import {
  Box,
  Container,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import config from "../../../config/config";
import textHighLightImg from "../../../assets/images/text-highlight.svg";
import { PrimaryButton, PrimaryInput, WaitingListSuccessModal } from "../../components";
import { AuthContext } from "../../../context";
import { toast } from "react-toastify";

export default function WaitingListForm(props) {

  const { sendWaitingList } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const submit = async() => {
    if (!formData.name || !formData.email) {
      setShowError(true);
    } else {
      setLoading(true);
      const result = await sendWaitingList(formData);
      setLoading(false);
      if (result) {
        setOpenModal(true);
      } else {
        toast.warning("Something went wrong.");
      }
    }      
  };

  return (
    <Box
      sx={{
        paddingY: {
          sm: 7,
          xs: 4,
        },
        borderBottom: `1px dotted ${config.colors.primaryTextColor}`,
        zIndex: 1201,
        position: 'relative',
      }}
    >
      <Container maxWidth={"lg"}>
        <Typography
          sx={{
            color: config.colors.primaryTextColor,
            fontSize: {
              sm: "30px",
              xs: "22px",
            },
            fontFamily: "Libre Franklin",
            fontWeight: "bold",
          }}
        >
          Enter your email below to be added to the
          <span
            style={{
              backgroundImage: `url(${textHighLightImg})`,
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              paddingBottom: 5,
              paddingLeft: 6,
            }}
          >
            waiting list.
          </span>
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ 
            mt: {
              sm: 3,
              xs: 1,
            }, 
            mr: 3, 
          }}
        >
          <Grid item xs={12} md={4}>
            <InputLabel
              sx={{
                color: config.colors.primaryTextColor,
                fontFamily: "Libre Franklin",
                fontSize: {
                  sm: "20px",
                  xs: "16px",
                },
                fontStyle: "normal",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Enter your name:
            </InputLabel>
            <PrimaryInput
              placeholder={"your name"}
              type="text"
              name={"name"}
              value={formData.name}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  name: e.target.value,
                });
              }}
              error={showError}
              sx={{
                height: 50,
                width: "100%",
              }}
            />
          </Grid>
          <Grid
          item xs={12} md={4}
          >
            <InputLabel
              sx={{
                color: config.colors.primaryTextColor,
                fontFamily: "Libre Franklin",
                fontSize: {
                  sm: "20px",
                  xs: "16px",
                },
                fontStyle: "normal",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Enter your email:
            </InputLabel>
            <PrimaryInput
              placeholder={"email address"}
              type="email"
              name={"email"}
              value={formData.email}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              error={showError}
              sx={{
                height: 50,
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            item xs={12} md={4}
          >
          <PrimaryButton
            label={"Request Early Access"}
            sx={{
              width: {
                sm: 270,
                xs: "80%",
              },
              height: {
                sm: 50,
                xs: 40,
              },
              mt: {
                sm: 5,
                xs: 1,
              },
            }}
            disabled={loading}
            handleClick={() => {
              submit();
            }}
          />
          </Grid>
        </Grid>
      </Container>
      <WaitingListSuccessModal
        isOpenModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </Box>
  );
}
