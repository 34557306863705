
import { useContext } from 'react';
import { AuthContext } from '../../../context';
import imgPoem from '../../../assets/images/poem2.svg';
import imgHandBook from '../../../assets/images/hand-book.svg';

export default function StoryBanner () {

    const { user } = useContext(AuthContext);

    return (
        <section className="story-banner">
            <div className="container position-relative">
                <div className="banner-wrapper sub-container">
                    <h1 className="headline position-relative zindex-100">Browse A Story</h1>
                    <p className="subline position-relative zindex-100">Welcome back{user ? ', ' : ''}{user?.firstName ? user?.firstName : user?.username}!</p>
                    <img src={imgPoem} className="img-poem" alt="" />
                    <img src={imgHandBook} className="img-hand-book" alt="" />
                </div>
            </div>
        </section>
    );
}