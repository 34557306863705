import React from "react";
import { Grid, Skeleton, Stack } from "@mui/material";

export default function LibraryItemSkeleton() {
  return (
    <Grid container mt={3} spacing={4}>
      <Grid item xs={12} md={5} lg={3}>
        <Skeleton variant="rounded" width={"100%"} height={"100%"} />
      </Grid>
      <Grid item xs={12} md={7} lg={9}>
        <Stack direction={"column"} spacing={2}>
          <Skeleton variant="rounded" width={"70%"} height={40} />
          <Skeleton variant="rounded" width={"100%"} height={120} />
          <Skeleton variant="rounded" width={"60%"} height={30} />
        </Stack>
      </Grid>
    </Grid>
  );
}
