import React, { createContext, useContext } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';
import { AuthContext } from './auth_context';

const AdminContext = createContext();

const AdminContextProvider = (props) => {

    const { axiosInstance } = useContext(AxiosContext);
    const { setUser, saveToken } = useContext(AuthContext);

    const adminLogin = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.adminLogin,
            data,
        }).then(response => {
            const data = response.data;
            if (data.status) {
                setUser(data.data?.user);
                saveToken(data.data?.user?.token);
            }
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    }

    const adminSendGridEmails = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.adminSendGridEmails,
        }).then(response => {
            const data = response.data;
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    };

    const sendSendGridEmail = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.adminSendGridEmails,
            data,
        }).then(response => {
            const data = response.data;
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    };

    const getWaitingUsers = (params) => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.adminWaitingUsers}?${params}`,
        }).then(response => {
            const data = response.data;
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    };

    const createWaitingUser = (data) => {
        return axiosInstance({
            method: 'POST',
            url: `${apis.adminWaitingUsers}`,
            data,
        }).then(response => {
            const data = response.data;
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    };

    const deleteWaitingUser = (id) => {
        return axiosInstance({
            method: 'DELETE',
            url: `${apis.adminWaitingUsers}/${id}`,
        }).then(response => {
            const data = response.data;
            return data;
        }).catch(error => {
            console.log('err: ', error);
            return null;
        });
    };

    const providerValue = {
        adminLogin,
        adminSendGridEmails,
        sendSendGridEmail,
        getWaitingUsers,
        createWaitingUser,
        deleteWaitingUser,
    }

    return (
        <AdminContext.Provider value={providerValue}>
            {props.children}
        </AdminContext.Provider>
    );

}

export {
    AdminContext, AdminContextProvider
}