import { useParams, useNavigate } from "react-router-dom";
import { MainHeader, Footer, PrimaryButton } from "../../components";
import "../../styles/story.scss";
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useEffect, useContext, useState, useRef } from "react";
import {
  AxiosContext,
  UserContext,
  AuthContext,
  ServiceContext,
} from "../../../context";
import imgAuthor from "../../../assets/images/avatar.svg";
import config from "../../../config/config";
import { toast } from "react-toastify";

export default function ProfileEditPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { setLoading } = useContext(AxiosContext);
  const { token, user, getMyInfo } = useContext(AuthContext);
  const { getMyProfile, updateUser } = useContext(UserContext);
  const { uploadImage } = useContext(ServiceContext);
  const fileRef = useRef(null);
  const [formData, setFormData] = useState({
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    imageUrl: "",
    about: "",
    website: "",
    location: "",
    twitter: "",
    facebook: "",
    linkedin: "",
  });
  const [imgPreview, setImgPreview] = useState(imgAuthor);

  useEffect(() => {
    if (token && user) {
      getUserDetailInfo(user.id);
    }
    window.scrollTo(0, 0);
  }, [token, user]);

  const getUserDetailInfo = (userId) => {
    setLoading(true);
    getMyProfile(userId).then((res) => {
      setLoading(false);
      if (res.status) {
        setFormData({
          ...formData,
          id: res.data.id && res.data.id !== null ? res.data.id : "",
          username:
            res.data.username && res.data.username !== null
              ? res.data.username
              : "",
          firstName:
            res.data.firstName && res.data.firstName !== null
              ? res.data.firstName
              : "",
          lastName:
            res.data.lastName && res.data.lastName !== null
              ? res.data.lastName
              : "",
          imageUrl:
            res.data.imageUrl && res.data.imageUrl !== null
              ? res.data.imageUrl
              : "",
          about:
            res.data.about && res.data.about !== null ? res.data.about : "",
          website:
            res.data.website && res.data.website !== null
              ? res.data.website
              : "",
          location:
            res.data.location && res.data.location !== null
              ? res.data.location
              : "",
          twitter:
            res.data.twitter && res.data.twitter !== null
              ? res.data.twitter
              : "",
          facebook:
            res.data.facebook && res.data.facebook !== null
              ? res.data.facebook
              : "",
          linkedin:
            res.data.linkedin && res.data.linkedin !== null
              ? res.data.linkedin
              : "",
        });
      }
    });
  };

  const handleInputChange = (e) => {
    let data = { ...formData };
    const target = e.target;
    data[target.name] = target.value;
    setFormData(data);
  };

  const onSave = () => {
    setLoading(true);
    updateUser(formData).then((res) => {
      toast("Successfully updated.");
      navigate(`/user/${user?.id}`);
    });
  };

  const onChangePassword = () => {
    navigate("/profile/change-password");
  };

  const uploadFile = (event, targetName) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    if (file.size > config.uploadMaxSize.image) {
      toast(
        "Please upload image less than " +
          config.uploadMaxSize.image / 1000000 +
          "MB."
      );
      return;
    }
    let reader = new FileReader();

    reader.onloadend = () => {
      setImgPreview(reader.result);
      uploadImage({ base64: reader.result }).then((response) => {
        let data = { ...formData };
        data[targetName] = response.data;
        setFormData(data);
      });
    };
    reader.onerror = (error) => {
      console.log(error);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      <MainHeader />
      <Container maxWidth={"xl"} sx={{ mb: 6 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1}>
            <PrimaryButton
              label={"Back"}
              handleClick={() => {navigate(`/user/${user?.id}`);}}
              startIcon={<ArrowBackOutlinedIcon />}
              sx={{
                height: 35,
                fontSize: 14,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            {formData && (
              <Box>
                <Stack
                  direction={{
                    xs: "column",
                    md: "row",
                  }}
                  alignItems={"center"}
                  spacing={3}
                >
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Avatar
                      src={formData?.imageUrl ? formData?.imageUrl : imgPreview}
                      sx={{
                        width: "179px",
                        height: "179px",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <input
                        accept="image/*"
                        className="d-none"
                        type="file"
                        ref={fileRef}
                        onChange={(e) => uploadFile(e, "imageUrl")}
                      />
                      <IconButton
                        color="warning"
                        aria-label="upload picture"
                        component="span"
                        size="large"
                        className="btn-upload-icon"
                        onClick={() => fileRef.current.click()}
                      >
                        <PhotoCamera />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: config.colors.primaryTextColor,
                        fontFamily: "Libre Franklin",
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      {formData?.fullName
                        ? formData?.fullName
                        : formData?.username}
                    </Typography>
                    <Typography
                      sx={{
                        color: config.colors.primaryTextColor,
                        fontFamily: "Libre Franklin",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "28px",
                      }}
                    >
                      {formData?.followerUsers
                        ? formData?.followerUsers.filter(
                            (follower) => follower.active
                          ).length
                        : 0}{" "}
                      Followers •{" "}
                      {formData?.followingUsers
                        ? formData?.followingUsers.filter(
                            (following) => following.active
                          ).length
                        : 0}{" "}
                      Following
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ mt: 4 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            First Name
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={formData?.firstName}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Last Name
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={formData?.lastName}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 d-flex align-items-center justify-content-end pt-1">
                            About
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <textarea
                            type="text"
                            className="form-control"
                            rows={7}
                            name="about"
                            value={formData?.about}
                            onChange={(event) => handleInputChange(event)}
                          ></textarea>
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Website
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="website"
                            value={formData?.website}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Location
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="location"
                            value={formData?.location}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Twitter
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="twitter"
                            value={formData?.twitter}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Facebook
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="facebook"
                            value={formData?.facebook}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <label className="w-100 h-100 d-flex align-items-center justify-content-end">
                            Linkedin
                          </label>
                        </Grid>
                        <Grid item xs={10}>
                          <input
                            type="text"
                            className="form-control"
                            name="linkedin"
                            value={formData?.linkedin}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    sx={{ mt: 3 }}
                  >
                    <PrimaryButton label={"Save"} handleClick={onSave} />
                  </Stack>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
}
