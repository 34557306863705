import { useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { MainHeader, StoryBanner, Footer, StoryListItem } from '../../components';
import '../../styles/category_stories.scss';
import { AxiosContext, SearchContext } from '../../../context';

export default function CategoryStoriesPage() {

    const { categorySlug } = useParams();
    const {
        searchStories,
        filterStories,
    } = useContext(SearchContext);
    const { setLoading } = useContext(AxiosContext);

    useEffect(() => {
        setLoading(true);
        if(categorySlug) {
            console.log('categorySlug', categorySlug);
            searchStories('', {categorySlug: [categorySlug], status: "PUBLISH"}).then((res) => {
                console.log('>>res>>', res.data)
                setLoading(false);
            });
        }
    }, [categorySlug]);

    return (
        <div className="page stories bg-white">
            <MainHeader />
            <StoryBanner />
            <section className="section-stories">
                <div className="container">
                    <div className="story-list sub-container">
                        {
                            filterStories && filterStories.map((item, index) =>
                                <StoryListItem item={item} key={index} />
                            )
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}