import React, { useState, useContext } from 'react';
import {
    Typography
} from '@mui/material';
import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context';
import config from '../../../config/config';
import '../../styles/auth.scss';
import { Footer } from '../../components';

import imgBookPad from '../../../assets/images/bookpad.svg';
  
  export default function RegisterStep1Page() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const theme = createTheme();
    const { register, socialAuth } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const postData = {
        email: data.get('email'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        fullName: data.get('firstName') + ' ' + data.get('lastName'),
        password: data.get('password'),
        conPassword: data.get('conPassword')
      };
      setLoading(true);
      const user = await register(postData);
      setLoading(false);
      if (user.token) {
        // window.location.href = window.location.protocol + '//' + window.location.host;
        navigate('/');
      } else {
        toast(user);
      }
    };

    const onResponseGoogleFailure = (response) => {
      // toast('Something is wrong');
      console.log(response);
    }

    const onResponseGoogle = async(response) => {
      const { email, familyName, givenName, googleId, imageUrl, name } = response.profileObj;
      const postData = {
        email: email,
        firstName: givenName,
        lastName: familyName,
        fullName: name,
        imageUrl: imageUrl.split('?')[0],
        provider: config.auth.provider.google,
        providerId: googleId,
      };
      setLoading(true);
      const user = await socialAuth(postData);
      setLoading(false);
      if (user.token) {
        window.location.href = window.location.protocol + '//' + window.location.host;
      } else {
        toast(user);
      }
    }

    const onResponseFacebookFailure = (response) => {
      // toast('Something is wrong');
      console.log(response);
    }

    const onResponseFacebook = async(response) => {
      const { email, id, name, picture } = response;
      const postData = {
        email: email,
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1],
        fullName: name,
        imageUrl: picture?.data?.url.split('?')[0],
        provider: config.auth.provider.facebook,
        providerId: id,
      };
      setLoading(true);
      const user = await socialAuth(postData);
      setLoading(false);
      if (user.token) {
        window.location.href = window.location.protocol + '//' + window.location.host;
      } else {
        toast(user);
      }
    }
  
    return (
      <div className="page auth">
        <div className="container">
          <div className="auth-container">
            <div className="auth-banner">
              <div className="banner-img mb-5">
                <img src={imgBookPad} className="w-100" alt="" />
              </div>
            </div>
            <div className="auth-form mx-auto">
              <div className="auth-card">
                <h1 className="headline">You’re almost there.</h1>
                <p className="auth-subline">
                  We need some details to keep in contact with you.
                </p>
                <div className="form-wrapper">
                  <div className="form-group">
                    <label>Username</label>
                    <div className="form-control-wrapper">
                      <input type="text" className="form-control" placeholder="MG-tester" />
                      <span className="form-check-icon"></span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-control-wrapper">
                      <input type="email" className="form-control" placeholder="iam@mikegrossman.com" />
                      <span className="form-check-icon"></span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="form-control-wrapper">
                      <input type="password" className="form-control" placeholder="••••••••••" />
                      <span className="eye-icon"></span>
                      <span className="v-dotted-line"></span>
                      <span className="form-check-icon"></span>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-continue mt-4"
                      onClick={() => navigate(`/auth/register/step2${redirectPath ? '?redirect=' + redirectPath : ''}`)}
                    >Continue</button>
                  </div>
                </div>
                <div className="terms-line mb-4">
                  By continuing, you agree to Storymoir’s <Link className="auth-link">Terms of Service</Link> and <Link className="auth-link">Privacy Policy</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }