import React, { useEffect, useState, useContext, useRef } from "react";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  MainHeader,
  Footer,
  TitleList,
  LibraryItem,
  PrimaryButton,
  LibraryItemSkeleton,
  DeleteStoryConfirmModal,
  DeleteStorySuccessModal,
} from "../../components";
import Dropdown from "react-bootstrap/Dropdown";
import "../../styles/library.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  AxiosContext,
  StoryContext,
  AuthContext,
  GuestContext,
  ShareContext,
  ServiceContext,
} from "../../../context";
import imgDots from "../../../assets/images/icons/dots.svg";
import imgGoogle from "../../../assets/images/icons/google.svg";
import imgDropbox from "../../../assets/images/icons/dropbox.svg";
import imgUpload from "../../../assets/images/icons/share.svg";
import imgAvatar from "../../../assets/images/avatar.svg";
import imgSettings from "../../../assets/images/icons/settings.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../utils/helpers";

import Config from "../../../config/config";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";

import DropboxChooser from "react-dropbox-chooser";
import useDrivePicker from "react-google-drive-picker";
import { Box, Container, Stack, Typography } from "@mui/material";
import config from "../../../config/config";

function str2xml(str) {
  if (str.charCodeAt(0) === 65279) {
    // BOM sequence
    str = str.substr(1);
  }
  return new DOMParser().parseFromString(str, "text/xml");
}

// Get paragraphs as javascript array
function getParagraphs(content) {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files["word/document.xml"].asText());
  const paragraphsXml = xml.getElementsByTagName("w:p");
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = "";
    const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0].nodeValue;
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
}

export default function LibraryPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPrevRoute } = useContext(GuestContext);
  const {
    getMyStories,
    myStories,
    setMyStories,
    setMyStoriesPagination,
    updateStory,
    updateStoryPartsOrder,
    deleteStory,
    updateStoryPart,
    deleteStoryPart,
    checkStoryNoteByStoryId,
    importStoryFromParagraphs,
  } = useContext(StoryContext);
  const { token } = useContext(AuthContext);
  const { setLoading } = useContext(AxiosContext);
  const { shareStory } = useContext(ShareContext);
  const { readFileFromDropbox, readFileFromGoogleDrive } =
    useContext(ServiceContext);
  const tabs = [
    {
      label: "Saved Stories",
      value: "saved_stories",
    },
    {
      label: "Published",
      value: "published",
    },
    {
      label: "Unpublished",
      value: "unpublished",
    },      
  ];
  const [getParams, setGetParams] = useState("");
  const [activeTab, setActiveTab] = useState("saved_stories"); // published, unpublished, saved
  const [showImportModal, setShowImportModal] = useState(false);
  const [modalStory, setModalStory] = useState(null);
  const [shareModal, setShareModal] = useState(null);
  const [shareFormData, setShareFormData] = useState({
    shareEmail: "",
    userId: "",
    storyId: "",
    shareRole: "Viewer",
  });
  const [showLoading, setShowLoading] = useState(false);
  const fileRef = useRef(null);
  const [openPicker, authResponse] = useDrivePicker();
  const [openDeleteStoryModal, setOpenDeleteStoryModal] = useState(false);
  const [openDeleteStorySuccessModal, setOpenDeleteStorySuccessModal] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickMenu = (route) => {
    setPrevRoute(location);
    navigate(route);
  };

  useEffect(() => {
    if (token) {
      setMyStories([]);
      setMyStoriesPagination(null);
      setShowLoading(true);
      getMyStories(getParams).then((res) => {
        setShowLoading(false);
      });
    }
  }, [token, getParams]);

  const handleTitleChange = (v) => {
    setActiveTab(v);
    if (v === "published") {
      setGetParams("status=PUBLISH");
    } else if (v === "unpublished") {
      setGetParams("status=DRAFT");
    } else {
      setGetParams("");
    }
  };

  const handleClickNotes = (story) => {
    checkStoryNoteByStoryId(story?.id).then((res) => {
      if (res.status) {
        setPrevRoute(location);
        navigate(`/mystories/${res.data.story?.id}/notes/${res.data.id}`);
      } else {
        console.log("err: ", res);
      }
    });
  };

  const handleShare = async (story) => {
    setShareModal(story);
    setShareFormData({
      ...shareFormData,
      userId: story?.user?.id,
      storyId: story?.id,
    });
  };

  const handleClickStoryDelete = (story) => {
    setLoading(true);
    deleteStory(story?.id).then((res) => {
      setLoading(false);
      setOpenDeleteStoryModal(false);
      if (res.status) {
        setOpenDeleteStorySuccessModal(true);
        getMyStories(getParams);
      }
      // toast(res.data);
    });
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...modalStory.storyParts];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setModalStory({ ...modalStory, storyParts: updatedList });
    updateStoryPartsOrder({ storyParts: updatedList }).then((res) => {
      if (res.status) {
        getMyStories(getParams);
      }
    });
  };

  const handleClickDetails = (story) => {
    setPrevRoute(location);
    navigate(`/mystories/${story?.id}`);
  };

  const handleClickPublish = (story) => {
    const data = {
      id: story?.id,
      status: "PUBLISH",
    };
    setLoading(true);
    updateStory(data).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
      } else {
        toast(res.data);
      }
    });
  };

  const handleClickUnpublish = (story) => {
    const data = {
      id: story?.id,
      status: "DRAFT",
    };
    setLoading(true);
    updateStory(data).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
      } else {
        toast(res.data);
      }
    });
  };

  const handleClickShare = (story) => {
    let linkText = `${window.location.origin}/story/${story.id}?utm_source=web&utm_medium=email&utm_content=sharing`;
    let mailtoLink = `mailto:?subject=${story.title}&body=${story.title}<br>${linkText}`;
    window.location.assign(mailtoLink);
  };

  const handleClickStoryPartPublishToggle = (storyPart) => {
    setLoading(true);
    updateStoryPart({
      ...storyPart,
      status: storyPart.status === "PUBLISH" ? "DRAFT" : "PUBLISH",
    }).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
        if (res.data.story) setModalStory(res.data.story);
      } else {
        toast(res.data);
      }
    });
  };

  const handleClickStoryPartDelete = (storyPartId) => {
    setLoading(true);
    deleteStoryPart(storyPartId).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
        if (res.data.story) setModalStory(res.data.story);
      } else {
        toast(res.data);
      }
    });
  };

  const handleChangeAccessType = (option) => {
    setLoading(true);
    updateStory({ id: shareModal.id, accessType: option }).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
        if (res.data.story) setShareModal(res.data.story);
        toast("Successfully updated.");
      } else {
        toast(res.data);
      }
    });
  };

  const handleClickCopyLink = () => {
    let linkText = `${window.location.origin}/story/${shareModal.id}?utm_source=web&utm_medium=email&utm_content=sharing`;
    navigator.clipboard.writeText(linkText);
    toast("Link copied");
  };

  const handleClickShareDone = () => {
    setShareModal(null);
  };

  const handleClickShareSend = () => {
    setLoading(true);
    shareStory(shareFormData).then((res) => {
      setLoading(false);
      if (res.status) {
        getMyStories(getParams);
        if (res.data.story) {
          setShareModal(res.data.story);
        }
        toast("Successfully shared.");
      } else {
        toast(res.data);
      }
      setShareFormData({ ...shareFormData, shareEmail: "" });
    });
  };

  // import story from docx file
  const onFileUpload = (event) => {
    const reader = new FileReader();
    let file = event.target.files[0];

    reader.onload = (e) => {
      const content = e.target.result;
      const paragraphs = getParagraphs(content);
      if (!!paragraphs && paragraphs.length > 0) {
        importStory(paragraphs);
      }
    };

    reader.onerror = (err) => console.error(err);

    reader.readAsBinaryString(file);
  };

  const importStory = (paragraphs) => {
    importStoryFromParagraphs({ paragraphs }).then((res) => {
      if (res.status) {
        setLoading(true);
        getMyStories(getParams).then((res) => {
          setLoading(false);
          setShowImportModal(false);
        });
      } else {
        toast(res.data);
      }
    });
  };

  // import from Dropbox
  const onSuccessDropbox = (files) => {
    setShowImportModal(false);
    let filePath =
      files && files.length > 0 ? `${files[0].link.split("=")[0]}=1` : "";
    readFileFromDropbox({ filePath }).then((res) => {
      if (res.status && res.data) {
        // createStoryDetailsAndWritePartByImport(res.data);
        importStory(res.data);
      } else {
        toast("Not supported file type.");
      }
    });
  };

  const onCancelDropbox = () => {
    console.log("== dropbox canceled ==");
  };

  // Google drive
  const handleOpenPicker = () => {
    openPicker({
      clientId: Config.googleDrive.clientId,
      developerKey: Config.googleDrive.apiKey,
      viewId: "DOCUMENTS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      viewMimeTypes: "application/vnd.google-apps.document",
      callbackFunction: (data) => {
        if (data.action === "loaded") {
          setShowImportModal(false);
        }
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          const doc = data.docs[0];
          const fileId = doc.id;
          const mimeType = doc.mimeType;
          const filePath = doc.url;
          readFileFromGoogleDrive({ fileId, filePath, mimeType }).then(
            (res) => {
              if (res.status && res.data) {
                importStory(res.data);
              } else {
                toast("Not supported file type.");
              }
            }
          );
        }
      },
    });
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      <MainHeader />
      <Container maxWidth="lg" sx={{
        pb: "60px"
      }}>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb= {3}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: "Lora",
              fontWeight: "600",
              color: config.colors.primaryTextColor,
            }}
          >
            Your Library
          </Typography>
          <PrimaryButton
            label={"Write"}
            handleClick={() => handleClickMenu(`/mystories/new`)}
          />
        </Stack>
        <TitleList handleChange={(v) => handleTitleChange(v)} tabs={tabs} />
        { showLoading ? [...Array(3)].map((item, index) => {
          return <LibraryItemSkeleton key={index} item={item}/>
        }) : myStories && myStories.length > 0 ? (
          myStories.map((item, index) => {
            return <LibraryItem
            key={index}
            item={item}
            activeTab={activeTab}
            handleClickShare={handleClickShare}
            handleClickDetails={handleClickDetails}
            handleClickNotes={handleClickNotes}
            setModalStory={setModalStory}
            handleClickUnpublish={handleClickUnpublish}
            handleClickPublish={handleClickPublish}
            handleShare={handleShare}
            handleClickStoryDelete={() => {
              setSelectedStory(item);
              setOpenDeleteStoryModal(true);
            }}
          />
          })
        ) : (
          <p className="text-center p-5">There is no {activeTab} story.</p>
        )}
      </Container>
      <Footer />
      <DeleteStoryConfirmModal
      isOpenModal={openDeleteStoryModal}
      closeModal={() => {setOpenDeleteStoryModal(false)}}
      confirm={() => handleClickStoryDelete(selectedStory)}
      />
      <DeleteStorySuccessModal
      isOpenModal={openDeleteStorySuccessModal}
      closeModal={() => {setOpenDeleteStorySuccessModal(false)}}
      confirm={() => {setOpenDeleteStorySuccessModal(false)}}
      />
      {/* Modals */}
      {/* Import A Story Modal */}
      <Modal
        show={showImportModal}
        onHide={() => setShowImportModal(false)}
        backdrop="static"
        keyboard={false}
        className="import-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Import A Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="import-options">
            <Button
              className="btn-import-from-google"
              onClick={() => handleOpenPicker()}
            >
              <img src={imgGoogle} className="" alt="" />
              <div>Google</div>
            </Button>
            <DropboxChooser
              appKey={Config.dropbox.appKey}
              success={(files) => onSuccessDropbox(files)}
              cancel={() => onCancelDropbox()}
              multiselect={false}
              extensions={[".docx"]}
            >
              <Button className="btn-import-from-dropbox dropbox-button">
                <img src={imgDropbox} className="" alt="" />
                <div>Dropbox</div>
              </Button>
            </DropboxChooser>
            <Button
              className="btn-import-from-upload"
              onClick={() => fileRef.current.click()}
            >
              <img src={imgUpload} className="" alt="" />
              <div>Upload</div>
            </Button>
            <input
              type="file"
              className="d-none"
              accept=".docx"
              ref={fileRef}
              onChange={onFileUpload}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="btn-exit mx-auto"
            onClick={() => setShowImportModal(false)}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Share Story Modal */}
      <Modal
        show={shareModal}
        onHide={() => setShareModal(null)}
        backdrop="static"
        keyboard={false}
        className="story-action-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Share Story</Modal.Title>
          <Button className="btn-settings">
            <img src={imgSettings} alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="modal-subline">
              “Remembering Grandma, word limit...”
            </div>
            <div className="form-group">
              <label>Add people and groups</label>
              {/* <AsyncAutoComplete /> */}
              <div className="share-form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mom@yahoo.com"
                  value={shareFormData.shareEmail}
                  onChange={(e) =>
                    setShareFormData({
                      ...shareFormData,
                      shareEmail: e.target.value,
                    })
                  }
                />
                {shareFormData.shareEmail &&
                  isValidEmail(shareFormData.shareEmail) && (
                    <select
                      className="form-control share-role"
                      value={shareFormData.shareRole}
                      onChange={(e) =>
                        setShareFormData({
                          ...shareFormData,
                          shareRole: e.target.value,
                        })
                      }
                    >
                      <option value="Viewer">Viewer</option>
                      <option value="Commenter">Commenter</option>
                      <option value="Editor">Editor</option>
                    </select>
                  )}
              </div>
            </div>
            <h5 className="modal-subtitle">People with access</h5>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="user-item">
                  <div className="user-avatar">
                    <img src={imgAvatar} alt="" />
                  </div>
                  <div className="user-info">
                    <div className="user-name">
                      {shareModal?.user?.fullName
                        ? shareModal?.user?.fullName
                        : shareModal?.user?.username}{" "}
                      (You - Owner)
                    </div>
                    <div className="user-email">{shareModal?.user?.email}</div>
                  </div>
                </div>
              </div>
              {shareModal &&
                shareModal?.shares?.map((item, index) => (
                  <div className="col-12 col-sm-6 col-md-6" key={index}>
                    <div className="user-item">
                      <div className="user-avatar">
                        <img src={imgAvatar} alt="" />
                      </div>
                      <div className="user-info">
                        <div className="user-name">
                          {item?.shareUser?.fullName
                            ? item?.shareUser?.fullName
                            : item?.shareUser?.username}{" "}
                          ({item?.shareRole})
                        </div>
                        <div className="user-email">
                          {item?.shareUser?.email
                            ? item?.shareUser?.email
                            : item?.shareEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <h5 className="modal-subtitle">General access</h5>
            <div className="access-option">
              <Dropdown className="access-option-dropdown">
                <Dropdown.Toggle variant="default">
                  {shareModal?.accessType == "RESTRICTED"
                    ? "Restricted"
                    : "Anyone with the link"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleChangeAccessType("RESTRICTED")}
                  >
                    Restricted
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleChangeAccessType("ANYONE")}
                  >
                    Anyone with the link
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <p>
                {shareModal?.accessType == "RESTRICTED"
                  ? "Only people with access can open with the link"
                  : "Anyone on the internet with the link can view"}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            className="me-auto btn-copy-link"
            onClick={() => handleClickCopyLink()}
          >
            Copy Link
          </Button>
          {shareFormData.shareEmail &&
          isValidEmail(shareFormData.shareEmail) ? (
            <Button
              variant="primary"
              className="ms-auto btn-close-modal"
              onClick={() => handleClickShareSend()}
            >
              Send
            </Button>
          ) : (
            <Button
              variant="primary"
              className="ms-auto btn-close-modal"
              onClick={() => handleClickShareDone()}
            >
              Done
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* Story Organizer Modal */}
      <Modal
        show={modalStory !== null}
        onHide={() => setModalStory(null)}
        backdrop="static"
        keyboard={false}
        className="story-action-modal lg-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Story Organizer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="modal-subline">“{modalStory?.title}”</div>
            <p>Drag and drop chapters to reorder your story.</p>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="part-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {modalStory?.storyParts.map((item, index) => (
                      <Draggable
                        key={item.id.toString()}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="part-item"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <div className="part-preview"></div>
                            <div className="part-info">
                              <div className="part-headline">
                                {`Chapter ${index + 1}`}
                              </div>
                              <div className="part-subline">{item.title}</div>
                            </div>
                            <Dropdown className="action-dropdown ms-auto">
                              <Dropdown.Toggle variant="default">
                                <img src={imgDots} className="" alt="..." />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate(
                                      `/story/${item.storyId}/${item.id}`
                                    )
                                  }
                                >
                                  View
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  onClick={() => {
                                    setPrevRoute(location);
                                    navigate(
                                      `/mystories/${item.storyId}/write/${item.id}?utm_source=library`
                                    );
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  onClick={() =>
                                    handleClickStoryPartPublishToggle(item)
                                  }
                                >
                                  {item.status === "PUBLISH"
                                    ? "Unpublish"
                                    : "Publish"}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  onClick={() =>
                                    handleClickStoryPartDelete(item.id)
                                  }
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="btn-close-modal mx-auto"
            onClick={() => setModalStory(null)}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
