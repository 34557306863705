import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import config from "../../../config/config";

export default function TitleList(props) {
  const { tabs, handleChange } = props;
  const [value, setValue] = useState(tabs[0].value);

  const onChange = (event, newValue) => {
    setValue(newValue);
    handleChange(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              borderBottom: 3,
              borderColor: "divider",
              borderBottomStyle: "dotted",
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          ></Box>
          <TabList
            onChange={onChange}
            TabIndicatorProps={{
              style: {
                background: config.colors.defaultColor,
                color: "white",
                paddingBottom: 3,
              },
            }}
            sx={{
              ".Mui-selected": {
                color: 'black !important',
                fontFamily: "Libre Franklin",
              },
            }}
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item.label}
                  value={item.value}
                  sx={{ textTransform: "none", fontFamily: "Libre Franklin", fontSize: "1.25rem"}}
                />
              );
            })}
          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
}
