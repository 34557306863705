import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/write.scss";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  MainHeader,
  Footer,
  DefaultButton,
  PrimaryButton,
  PrimaryInput,
  PrimaryTextArea,
  PrimarySelect,
  ImageUpload,
} from "../../components";
import Config from "../../../config/config";
import {
  AxiosContext,
  ServiceContext,
  StoryContext,
  CategoryContext,
  AuthContext,
  GuestContext,
} from "../../../context";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// import imgProfile from '../../../assets/images/avatar.svg';
import imgUpload from "../../../assets/images/icons/share.svg";
import { Avatar, Box, Chip, Container, Grid, InputLabel } from "@mui/material";
import config from "../../../config/config";
import TagInput from "../../components/inputs/tag_input";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 66,
  height: 33,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 27,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(29px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#DB805E" : "#DB805E",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 27,
    height: 27,
    borderRadius: 27 / 2,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 33 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function NewStoryPage() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AxiosContext);
  const { uploadImage } = useContext(ServiceContext);
  const { categories, getCategories } = useContext(CategoryContext);
  const { createStory } = useContext(StoryContext);
  const { token, user } = useContext(AuthContext);
  const {
    getTempStory,
    setTempStory,
    removeTempStory,
    getPrevRoute,
    isScrolling,
  } = useContext(GuestContext);
  const fileRef = useRef(null);
  const [tag, setTag] = useState("");
  const [preView, setPreView] = useState(null);
  const defaultFormData = {
    title: "Untitled Story",
    description: "",
    categoryId: 1,
    type: "",
    tags: "",
    language: "",
    copyright: "",
    rating: false,
    logo: null,
  };
  const [formData, setFormData] = useState({
    title: "Untitled Story",
    description: "",
    categoryId: 1,
    type: "",
    tags: "",
    language: "English",
    copyright: "",
    rating: false,
    logo: null,
  });
  const [showLoginPromptModal, setShowLoginPromptModal] = useState(false);

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
    console.log("*-*-*-*-", getTempStory());
    const tempStory = getTempStory();
    console.log(tempStory);
    if (tempStory) {
      setFormData(tempStory);
    }
  }, [token]);

  const handleKeyUpTagInput = (e) => {
    if (e.keyCode === 13 && e.target.value.trim()) {
      let tags = formData.tags;
      // tags = [...tags, e.target.value];
      tags += tags ? "#" + e.target.value : e.target.value;
      console.log("===", tags);
      setFormData({ ...formData, tags: tags });
      setTag("");
    }
  };

  const uploadFile = (event) => {
    console.log("- file upload - 1 -", event, token);
    if (!event.target.files || event.target.files.length === 0 || !token) {
      return;
    }
    const file = event.target.files[0];
    console.log("- file upload - 2 -", file);
    const objectUrl = URL.createObjectURL(file);
    setPreView(objectUrl);
    let reader = new FileReader();

    reader.onloadend = () => {
      setLoading(true);
      uploadImage({ base64: reader.result }).then((response) => {
        let storyInfo = { ...formData };
        storyInfo.logo = response.data;
        setFormData(storyInfo);
        setLoading(false);
      });
    };
    reader.onerror = (error) => {
      console.log(error);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    console.log(">>>", formData);
    if (token) {
      submitForm(formData);
    } else {
      setShowLoginPromptModal(true);
    }
  };

  const handleClickSkip = () => {
    if (token) {
      console.log("AAAA");
      submitForm(defaultFormData);
    } else {
      console.log("BBB");
      setShowLoginPromptModal(true);
    }
  };

  const submitForm = (fd) => {
    let _fd = fd;
    _fd.userId = user.id;
    setLoading(true);
    createStory(_fd).then((response) => {
      setLoading(false);
      console.log(">>>===>>>", response);
      if (response.status) {
        const storyId = response.data.storyPart.storyId;
        const storyPartId = response.data.storyPart.id;
        removeTempStory();
        navigate(`/mystories/${storyId}/write/${storyPartId}`);
      } else {
        toast(response.data);
      }
    });
  };

  const handleBack = () => {
    let prevRoute = getPrevRoute();
    navigate(prevRoute ? prevRoute.pathname : "/");
  };

  const handleExit = () => {
    setShowLoginPromptModal(false);
    removeTempStory();
    handleBack();
  };

  const handleSignUp = () => {
    setShowLoginPromptModal(false);
    setTempStory(formData);
    navigate(`/auth/register?redirect=/mystories/new`);
  };

  const handleLogin = () => {
    setShowLoginPromptModal(false);
    setTempStory(formData);
    navigate(`/auth/login?redirect=/mystories/new`);
  };

  const removeTag = (item, index) => {
    let tags = formData.tags;
    let tagsLength = tags.split("#");
    if (index === tagsLength - 1) {
      tags = tags.replace(`${item}`, "");
    } else {
      tags = tags.replace(`${item}#`, "");
    }
    setFormData({
      ...formData,
      tags: tags,
    });
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      <MainHeader />
      <Box
        sx={{
          position: isScrolling ? "fixed" : "absolute",
          top: isScrolling ? "93px" : "123px",
          width: "100%",
          height: isScrolling ? "100px" : "166px",
          display: "flex",
          alignItems: "center",
          bgcolor: config.colors.bgWhiteColor,
          boxShadow: isScrolling ? "0px 2px 5px 0 rgba(71,52,130,.1)" : "none",
          zIndex: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={5} alignItems={"center"}>
              <Avatar
                src={user?.imageUrl}
                sx={{
                  width: "70px",
                  height: "70px",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    color: config.colors.primaryTextColor,
                    fontFamily: "Libre Franklin",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                  }}
                >
                  Untitled Story
                </Typography>
                <Typography
                  sx={{
                    color: config.colors.greyTextColor,
                    fontFamily: "Libre Franklin",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  Draft
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <DefaultButton label={"Cancel"} handleClick={handleBack} />
              <PrimaryButton label={"Skip"} handleClick={handleClickSkip} />
            </Stack>
          </Stack>
        </Container>
      </Box>
      {/*  */}
      <Box
        sx={{
          bgcolor: "hsla(44,22%,87%,.3)",
          padding: "140px 0",
        }}
      >
        <Container maxWidth={"lg"}>
          <Typography
            sx={{
              marginBottom: "60px",
              marginTop: "40px",
              color: config.colors.primaryTextColor,
              fontStyle: "normal",
              fontFamily: "Lora",
              fontSize: "46px",
              fontWeight: 600,
              lineHeight: "55px",
            }}
          >
            Story Details
          </Typography>
          <Grid container spacing={5}>
            <Grid item md={12} lg={8}>
              <PrimaryInput
                label={"Title"}
                placeholder={"Untitled Story"}
                type={"text"}
                name={"title"}
                value={formData.title}
                handleChange={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={12} lg={8}>
              <PrimaryTextArea
                label={"Brief Summary"}
                subLabel={"(250 char.)"}
                name={"description"}
                value={formData.description}
                rows={5}
                maxLength={250}
                handleChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={12} lg={8}>
              <PrimarySelect
                label={"Story Type"}
                name={"type"}
                value={formData.type}
                options={[
                  { label: "Select a Story Type", value: "" },
                  { label: "Short Story", value: "shortstory" },
                  { label: "Memoir", value: "memoir" },
                  { label: "Biography", value: "biography" },
                ]}
                handleChange={(e) => {
                  setFormData({ ...formData, type: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={12} lg={8}>
              <InputLabel
                sx={{
                  color: config.colors.primaryTextColor,
                  fontFamily: "Libre Franklin",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  marginBottom: "10px",
                }}
              >
                Tags
              </InputLabel>
              <Typography
                sx={{
                  color: config.colors.primaryTextColor,
                  fontFamily: "Libre Franklin",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "28px",
                  marginBottom: "10px",
                }}
              >
                You can add tags to your story that describe things like the
                topics and details your story is about. This helps the readers
                who are most interested in your story find it in our search. We
                recommend that you add tags that describe the people,
                relationships, locations, time periods, topics and themes that
                pertain to your story. For example, a story about remembering a
                grandmother’s pie served during Thanksgiving in the 1970s, might
                be tagged with: Grandmother Stories, Pies, Thanksgiving, 1970s,
                Favorite Memories, Family Recipes. Tags can be added, edited, or
                removed at any time by accessing each story's details.
              </Typography>
              {formData.tags && formData.tags.length > 0 && (
                <Box sx={{ mb: "10px" }}>
                  {formData.tags?.split("#").map((item, index) => {
                    return (
                      <Chip
                        label={item}
                        key={index}
                        sx={{
                          color: config.colors.primaryTextColor,
                          fontFamily: "Libre Franklin",
                          fontSize: "14px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          cursor: "pointer",
                        }}
                        onDelete={() => {
                          removeTag(item, index);
                        }}
                      />
                    );
                  })}
                </Box>
              )}
              <TagInput
                type="text"
                value={tag}
                handleChange={(e) => setTag(e.target.value)}
                handleKeyUpChange={(e) => handleKeyUpTagInput(e)}
              />
            </Grid>
            {/* <Grid item md={12} lg={8}>
              <PrimarySelect
                label={"Language"}
                name={"language"}
                value={formData.language}
                options={[{ value: "English", label: "English" }]}
                handleChange={(e) => {
                  setFormData({ ...formData, language: e.target.value });
                }}
              />
            </Grid> */}
            <Grid item md={12} lg={8}>
              <InputLabel
                sx={{
                  color: config.colors.primaryTextColor,
                  fontFamily: "Libre Franklin",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  marginBottom: "10px",
                }}
              >
                Rating
              </InputLabel>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={formData.rating}
                  value={formData.rating}
                  onChange={(e) =>
                    setFormData({ ...formData, rating: e.target.checked })
                  }
                />
                <Typography sx={{
                  fontFamily: "Libre Franklin",
                  fontSize: "20px",
                  fontWeight: 400,
                  // lineHeight: 20,
                  color: "#8A8A8A",
                }}>Mature</Typography>
              </Stack>
            </Grid>
            <Grid item md={12} lg={8}>
              <ImageUpload
                fileRef={fileRef}
                preView={preView}
                imgUpload={imgUpload}
                handleChange={uploadFile}
              />
            </Grid>
            <Grid item md={12} lg={8}>
              <PrimaryButton
                label={"Done"}
                handleClick={() => handleSubmit()}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
      {/* Modals */}
      {/* Import A Story Modal */}
      <Modal
        show={showLoginPromptModal}
        onHide={() => setShowLoginPromptModal(false)}
        backdrop="static"
        keyboard={false}
        className="login-prompt-modal"
      >
        <Modal.Header className="border-0"></Modal.Header>
        <Modal.Body>
          <p className="subline text-center fw-bold">
            Please Login or Sign Up to continue...
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="btn-login mx-auto"
            onClick={() => handleLogin()}
          >
            Login
          </Button>
          <Button
            variant="primary"
            className="btn-signup mx-auto"
            onClick={() => handleSignUp()}
          >
            Sign Up
          </Button>
          <Button
            variant="primary"
            className="btn-exit mx-auto"
            onClick={() => handleExit()}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
