import React, { createContext, useContext, useState } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';
import Config from '../config/config';

const SurveyContext = createContext();

const SurveyContextProvider = (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const [ surveys, setSurveys ] = useState([]);
    const [ writerSurveys, setWriterSurveys ] = useState([]);
    const [ readerSurveys, setReaderSurveys ] = useState([]);

    const getSurveys = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.surveys,
        }).then(response => {              
            if (response.data.data) {
                setSurveys(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getWriterSurveys = () => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.surveys}/writer`,
        }).then(response => {              
            if (response.data.data) {
                setWriterSurveys(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getReaderSurveys = () => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.surveys}/reader`,
        }).then(response => {              
            if (response.data.data) {
                setReaderSurveys(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const createOrUpdateSurveyResults = (data) => {
        return axiosInstance({
            method: 'POST',
            url: `${apis.surveyResults}`,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }
    
    const providerValue = {
        surveys,
        getSurveys,
        writerSurveys,
        getWriterSurveys,
        readerSurveys,
        getReaderSurveys,
        createOrUpdateSurveyResults,
    }

    return (
        <SurveyContext.Provider value={providerValue}>
            {props.children}
        </SurveyContext.Provider>
    );
}

export {
    SurveyContext, SurveyContextProvider
}
