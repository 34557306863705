import { Box, IconButton, InputLabel, Stack, styled } from "@mui/material";
import config from "../../../config/config";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ImageUpload(props) {
  const { fileRef, handleChange, preView, imgUpload } = props;

  return (
    <Box>
      <InputLabel
        sx={{
          color: config.colors.primaryTextColor,
          fontFamily: "Libre Franklin",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 700,
          marginBottom: "10px",
        }}
      >
        Upload Image
      </InputLabel>
      <Stack>
        <Box
          sx={{
            cursor: "pointer",
            alignItems: "center",
            background: "#d9d9d9",
            border: "none",
            display: "flex",
            height: "240px",
            justifyContent: "center",
            width: "290px",
          }}
          onClick={() => fileRef.current.click()}
        >
          <VisuallyHiddenInput
            type="file"
            multiple={false}
            accept="image/png, image/jpeg"
            ref={fileRef}
            onChange={handleChange}
          />
          <img src={preView ? preView : imgUpload} alt="" width={"30%"} height={"100%"} style={{
            objectFit: "fill"
          }}/>
        </Box>
      </Stack>
    </Box>
  );
}
