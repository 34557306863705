import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/start.scss';
import { toast } from 'react-toastify';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
import { AxiosContext, DelegateContext } from '../../../context';
  
  export default function WriterInvitePage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const opt = searchParams.get("opt");

    const [email, setEmail] = useState('');
    const { setLoading } = useContext(AxiosContext);
    const { sendInviteToCoWriters } = useContext(DelegateContext);

    const handleInvitationSend = () => {
      setLoading(true);
      sendInviteToCoWriters({email}).then(res => {
        setLoading(false);
        if(res.status) {
          navigate(`/start/invite/success${opt === 'wr' ? '?opt=wr' : '' }`)
        } else {
          toast(res.data);
        }
      });
    }
  
    return (
      <div className="start">
        <div className="container py-60px">
          <div className="logo mx-auto mb-4">
            <Link to={`/`}>
              <img src={imgLogo} className="w-100" alt="" />
            </Link>
          </div>
          <div className="great-msg mx-auto">
            Great!
            <br />
            The easiest way to co-write stories with another person is to send them the link below to sign up for an account.
          </div>
          <div className="invite-form mx-auto">
            <label>Send a sign up link to:</label>
            <div className="inline-group">
              <input type="email" className="form-control" placeholder="email address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="btn btn-primary btn-send"
                // onClick={() => navigate(`/start/invite/success${opt === 'wr' ? '?opt=wr' : '' }`)}
                onClick={() => handleInvitationSend()}
              >Send</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}