import imgFilterStoryLength from "../../../../assets/images/icons/filter-story-length.svg";
import imgFilterStoryType from "../../../../assets/images/icons/filter-story-type.svg";
import imgFilterStorySort from "../../../../assets/images/icons/filter-story-sort.svg";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import config from "../../../../config/config";

export default function StoryFilterByOptions(props) {
  const { filterParams, handleChangeFilterParam } = props;

  const labelSx = {
    fontFamily: "Libre Franklin",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: config.colors.primaryTextColor,
    padding: "5px 3px",
  };

  return (
    <Box
        sx={{
          bgcolor: config.colors.primaryBgColor,
          padding: "25px 30px",
          borderRadius: "12px",
          minWidth: "600px",
        }}
      >
        <Typography sx={{
            fontSize: "20px",
            fontWeight: 700,
            textAlign: "center",
            color: config.colors.primaryTextColor,
            paddingBottom: "10px",
            fontFamily: "Libre Franklin",
        }}>STORY FILTERS</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography sx={{
                fontFamily: "Libre Franklin",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "42px",
                color: config.colors.primaryTextColor,
                display: "flex",
                alignItems: "center",
            }}>Story Length</Typography>
            <Stack direction={"column"} spacing={1} sx={{mt: 1}}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"1-2"}
                    checked={
                      filterParams.storyReadingTime?.includes("1-2")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyReadingTime", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>1-2 Minutes</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"2-15"}
                    checked={
                      filterParams.storyReadingTime?.includes("2-15")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyReadingTime", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>2-15 Minutes</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"15-"}
                    checked={
                      filterParams.storyReadingTime?.includes("15-")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyReadingTime", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>15+ Minutes</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{
                fontFamily: "Libre Franklin",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "42px",
                color: config.colors.primaryTextColor,
                display: "flex",
                alignItems: "center",
            }}>Story Type</Typography>
            <Stack direction={"column"} spacing={1} sx={{mt: 1}}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"shortstory"}
                    checked={
                      filterParams.storyType?.includes("shortstory")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyType", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>Short Stories</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"memoir"}
                    checked={
                      filterParams.storyType?.includes("memoir")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyType", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>Memoirs</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"biography"}
                    checked={
                      filterParams.storyType?.includes("biography")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("storyType", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>Biographies</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{
                fontFamily: "Libre Franklin",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "42px",
                color: config.colors.primaryTextColor,
                display: "flex",
                alignItems: "center",
            }}>Sort By</Typography>
            <Stack direction={"column"} spacing={1} sx={{mt: 1}}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"Recommend"}
                    checked={
                      filterParams.sortBy?.includes("Recommend")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("sortBy", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>Recommended</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"New"}
                    checked={
                      filterParams.sortBy?.includes("New")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("sortBy", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>New</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"Like"}
                    checked={
                      filterParams.sortBy?.includes("Like")
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleChangeFilterParam("sortBy", e)
                    }
                    sx={{
                        p: 0,
                        m: 0,
                    }}
                  />
                }
                label={<Typography sx={{
                    ...labelSx,
                }}>Most Liked</Typography>}
                sx={{
                    p: 0,
                    m: 0,
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
  );
}
