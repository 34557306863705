import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import logo from '../../../assets/images/STORYMOIR-LOGO.svg';
import avatar from '../../../assets/images/avatar.svg';

import { AuthContext, SearchContext, GuestContext, UserContext } from '../../../context';
import ReadingProgressBar from './reading_progress_bar';
import { AppBar, Box, Stack, Typography } from '@mui/material';
import config from '../../../config/config';

export default function BetaHeader({ showReadingProgress = false }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout } = useContext(AuthContext);
    const { searchText } = useContext(SearchContext);
    const { setPrevRoute, isScrolling, setIsScrolling } = useContext(GuestContext);
    const { getTopAuthors, topAuthors } = useContext(UserContext);

    const btnRef = useRef(null);
    const [headerClass, setHeaderClass] = useState('');
    const [queryText, setQueryText] = useState('');

    useEffect(() => {
        getTopAuthors();
        window.addEventListener('scroll', handleScrollEvent);
        return () => window.removeEventListener('scroll', handleScrollEvent);
    }, []);

    useEffect(() => {
        checkQueryText();
    }, [searchText]);

    const checkQueryText = () => {
        if(searchText && location.pathname.includes('search')) {
            setQueryText(searchText);
        }
    }

    const handleScrollEvent = (e) => {
        if(e.target.documentElement.scrollTop > 0) {
            setHeaderClass('scrolled');
            setIsScrolling(true);
        } else {
            setHeaderClass('');
            setIsScrolling(false);
        }
    }

    const handleEnterKey = (e) => {
        if(e.keyCode === 13) {
            // navigate(`/search/${queryText}`);
            if (queryText) {
                navigate(`/stories?query=${queryText}`);
            } else {
                navigate(`/stories`);
            }
            
        }
    }

    const handleClickMenu = (route) => {
        setPrevRoute(location);
        navigate(route);
    }

    const handleLogout = () => {
        logout().then(res => {
            if(res) {
                
            }
        });
    }

    return (
        <AppBar
            position='static'
            sx={{
                backgroundColor: '#fff',
                height: {
                    sm: "auto",
                    md: "160px"
                },
            }}
        >
            <Container
            >
                <Link to="/waiting-list">
                    <Box
                        sx={{
                            position: 'relative',
                            paddingRight: 3,
                            pt: 1,
                            maxWidth: "340px",
                            mt: {
                                md: "30px",
                                sm: 0,
                            }
                        }}
                    >
                        <img src={logo} alt="" style={{
                            maxHeight: "65px",
                            maxWidth: "331px"
                        }}/>
                        <Typography
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: config.colors.lightBlueColor,
                                color: "#fff",
                                fontWeight: "500",
                                fontSize: 13,
                                paddingX: 0.4,
                                paddingY: 0.1,
                                borderRadius: 1.5,
                            }}
                        >
                            BETA
                        </Typography>
                    </Box>
                </Link>
            </Container>
        </AppBar>
    );
};