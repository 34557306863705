import { Link, useLocation, useParams } from "react-router-dom";
import imgAuthor from '../../../assets/images/avatar.svg';
import iconTwitter from '../../../assets/images/icons/ic_twitter.svg';
import iconFacebook from '../../../assets/images/icons/ic_facebook.svg';
import iconLinkedin from '../../../assets/images/icons/ic_linkedin.svg';
import iconLink from '../../../assets/images/icons/ic_link.svg';
import iconShare from '../../../assets/images/icons/ic_share.svg';
import { useContext, useState } from "react";
import { AuthContext, UserContext, StoryContext } from "../../../context";
import { toast } from 'react-toastify';
import { AuthPromptModal } from '../../components';

export default function CoAuthorProfileBanner ({author, delegates}) {
    const location = useLocation();
    const { storyId } = useParams();
    const [showLoginPromptModal, setShowLoginPromptModal] = useState(false);
    const { token, user } = useContext(AuthContext);
    const { follow } = useContext(UserContext);
    const { getStory } = useContext(StoryContext);

    const handleFollowClick = (isfollowed) => {
        if(token && user) {
            follow(user.id, {followerId: author?.id, isFollow: !isfollowed})
                .then((res) => {
                    toast(res.data)
                    if(res.status) {
                        getStory(storyId);
                    }
                })
        } else {
            setShowLoginPromptModal(true);
        }
    }

    return (
        <section className="co-author-profile-banner">
            <div className="author-profile">
                <div className="author-img">
                    <Link className="text-decoration-none" to={`/user/${author?.id}`}>
                        <img src={author?.imageUrl ? author?.imageUrl : imgAuthor} className="" alt="" />
                    </Link>
                </div>
                <div className="author-info">
                    <Link className="text-decoration-none" to={`/user/${author?.id}`}>
                        <h2 className="author-name">{author?.fullName ? author?.fullName : author?.username}</h2>
                    </Link>
                    <div className="author-follows">
                        {author?.followerUsers ? author?.followerUsers.filter(follower => follower.active).length : 0} Followers  •  {author?.followingUsers ? author?.followingUsers.filter(following => following.active).length : 0} Following
                    </div>
                    {/* <button className="btn btn-primary btn-follow d-block d-lg-none">Follow</button> */}
                </div>
                <button className="btn btn-primary btn-follow"
                    onClick={() => handleFollowClick(author?.followerUsers?.filter(follower => (follower?.userId == author?.id && follower.active)).length > 0)}
                >{author?.followerUsers?.filter(follower => (follower?.userId == author?.id && follower.active)).length > 0 ? 'Unfollow' : 'Follow'}</button>
                <div className="co-authors">
                    <div className="co-author-label">Co-Authors:</div>
                    <div className="co-author-name">
                        {
                            delegates?.map((item, index) => 
                                <Link to={`/user/${item.coAuthor.id}`} key={index}>
                                    {index === 0 
                                        ? (item.coAuthor.fullName ? item.coAuthor.fullName : item.coAuthor.username) 
                                        : ', ' + (item.coAuthor.fullName ? item.coAuthor.fullName : item.coAuthor.username)}
                                </Link>
                            )
                        }
                    </div>
                    {/* <div className="socials d-flex d-lg-none">
                        <a href="" className="social-link">
                            <img src={iconTwitter} className="" alt="twitter" />
                        </a>
                        <a href="" className="social-link">
                            <img src={iconFacebook} className="" alt="facebook" />
                        </a>
                        <a href="" className="social-link">
                            <img src={iconLinkedin} className="" alt="linkedin" />
                        </a>
                        <a href="" className="social-link">
                            <img src={iconLink} className="" alt="link" />
                        </a>
                        <a href="" className="social-link">
                            <img src={iconShare} className="" alt="Share" />
                        </a>
                    </div> */}
                </div>
                <div className="socials">
                    <a href={author?.twitter} className="social-link" target="_blank">
                        <img src={iconTwitter} className="" alt="twitter" />
                    </a>
                    <a href={author?.facebook} className="social-link" target="_blank">
                        <img src={iconFacebook} className="" alt="facebook" />
                    </a>
                    <a href={author?.linkedin} className="social-link" target="_blank">
                        <img src={iconLinkedin} className="" alt="linkedin" />
                    </a>
                    <a href={author?.website} className="social-link" target="_blank">
                        <img src={iconLink} className="" alt="link" />
                    </a>
                    <a href={author?.share} className="social-link" target="_blank">
                        <img src={iconShare} className="" alt="Share" />
                    </a>
                </div>
            </div>
            {/* Modal */}
            <AuthPromptModal showModal={showLoginPromptModal} setShowModal={setShowLoginPromptModal} redirect={location.pathname} />
        </section>
    );
}