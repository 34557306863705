import React, { useContext } from 'react';
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import '../../../styles/story.scss';
import { CoAuthorProfileBanner, StoryTag } from '../..';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthContext } from '../../../../context';
import imgPencilIcon from '../../../../assets/images/icons/ic_pencil.svg';

// import featuredImg from '../../../../assets/images/family-lg.svg';

export default function StoryByCoAuthor(props) {
    
    const { story, chapter, chapterIndex, handlePagination } = props;
    const { storyId, storyPartId } = useParams();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { user } = useContext(AuthContext);
    
    return(
        <div className='story-template'>
            <section className="section-story">
                <div className="container">
                    <div className="story-container sub-container">
                        <h1 className="headline position-relative pe-4">
                            {story?.title}
                            {
                                story?.user?.id === user?.id
                                ? <Link className="btn-story-edit ms-3" title="Edit Chapter"
                                    to={`/mystories/${storyId}/write/${storyPartId ? storyPartId : chapter?.id}`}
                                >
                                    <img src={imgPencilIcon} className="object-fit-cover" alt="" />
                                </Link>
                                : null
                            }
                        </h1>
                        <div className="subline">
                            {chapter?.title}
                        </div>
                        <div className="featured-img row mx-0">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 px-0">
                                <img src={story?.logo} className="w-100 h-100 object-fit-cover" alt="" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 px-0">
                                <CoAuthorProfileBanner author={story?.user} delegates={story?.delegates} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-5 col-sm-6 col-md-6 d-flex align-items-center">
                                <div className="blog-head">Chapter {chapterIndex + 1}</div>
                            </div>
                            <div className="col-7 col-sm-6 col-md-6 d-flex">
                                <NavDropdown title="Skip to chapter" className="chapter-dropdown ms-auto mt-0">
                                    {
                                        story?.storyParts && story?.storyParts.map((item, index) =>
                                            <div key={index}>
                                                {index === 0 ? null : <NavDropdown.Divider />}
                                                <Link className={`dropdown-item ${index === chapterIndex ? 'active' : ''}`} to={`/story/${story.id}/${item.id}${hash}`}>Chapter {index + 1}</Link>
                                            </div>
                                        )
                                    }
                                </NavDropdown>
                            </div>
                        </div>
                        <div className="blog-content subline" dangerouslySetInnerHTML={{
                            __html: chapter?.content
                        }}>
                        </div>
                        <div className="blog-tail mt-4">Continue reading...</div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                                <div className="story-tags">
                                    {
                                        story?.tags && story?.tags.split('#').map((tag, index) =>
                                            <StoryTag tag={tag} key={index} />
                                        ) 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}