import React from "react";
import Modal from "@mui/material/Modal";
import logo from "../../../assets/images/STORYMOIR-LOGO.svg";
import textHighLightImg from "../../../assets/images/text-highlight.svg";
import { Box, Stack, TextField, Typography } from "@mui/material";
import config from "../../../config/config";
import PrimaryButton from "../buttons/primary_button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function WaitingListSuccessModal(props) {
  const { isOpenModal = false, closeModal } = props;

  return (
    <Modal
      open={isOpenModal}
      onClose={closeModal}
      aria-labelledby="Waiting List Confirmation Modal"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: "100%",
          }}
        >
          <img src={logo} width={300} alt="" />
        </Stack>
        <Typography
          sx={{
            textAlign: "center",
            mt: 3,
          }}
        >
          <span
            style={{
              backgroundImage: `url(${textHighLightImg})`,
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              paddingBottom: 5,
              fontFamily: "Lora",
              fontSize: "46px",
              fontStyle: "italic",
              lineHeight: "28px",
              fontWeight: 600,
            }}
          >
            Thanks
          </span>
        </Typography>
        <Typography
          sx={{
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: 400,
            mt: 5,
            paddingX: 2,
          }}
          textAlign={"center"}
        >
          You've now been added to the waiting list for early access to Stoirymoir
        </Typography>
        <Typography
          sx={{
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: 400,
            mt: 2,
            paddingX: 2,
          }}
          textAlign={"center"}
        >
          Look out in your inbox for an email from our team for when the full site goes live.
        </Typography>
        <Stack
          direction={"row"}
          mt={3}
          spacing={2}
          alignItems={"center"}
          justifyContent="center"
        >
          <PrimaryButton label="Ok" disabled={false} handleClick={closeModal} />
        </Stack>
      </Box>
    </Modal>
  );
}
