import { Box, InputLabel, Typography, Stack } from "@mui/material";
import config from "../../../config/config";

export default function TagInput(props) {
  const {
    placeholder,
    name,
    handleChange,
    handleKeyUpChange,
    value,
    type,
  } = props;

  return (
    <Box>
      <Stack direction={"row"}
        sx={{
          width: "140px",
          boxShadow: "inset 0px 2px 6px #d2d2d2",
          transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          background: "#fff!important",
          padding: "10px 10px",
          borderRadius: "24px",
        }}
        alignItems={"center"}
        spacing={0}

      >
        <Typography sx={{
          color: "#8a8a8a",
          fontFamily: "Libre Franklin",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "500",
          textAlign: "center",
        }}>+</Typography>
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyUp={handleKeyUpChange}
          value={value}
          style={{
            width: "100%",
            color: config.colors.primaryTextColor,
            border: "none",
            display: "flex",
            alignItems: "center",   
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",
            outline: "none",
            borderRadius: "0",
            borderColor: "transparent",
          }}
        />
      </Stack>
    </Box>
  );
}
