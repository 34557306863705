import { Box, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import config from '../../../config/config';

export default function ReadingProgressBar () {
    const [progress, setProgress] = useState(10);
    
    useEffect(() => {
        window.addEventListener("scroll", scrollHeight);
        return () => window.removeEventListener("scroll", scrollHeight);
    }, []);

    const scrollHeight = () => {
        var el = document.documentElement,
          ScrollTop = el.scrollTop || document.body.scrollTop,
          ScrollHeight = el.scrollHeight || document.body.scrollHeight;
        var percent = (ScrollTop / (ScrollHeight - el.clientHeight)) * 100;
        // store percentage in state
        setProgress(percent);
      };

    return (
        <Box>
            <LinearProgress variant={"determinate"} value={progress} sx={{
                bgcolor: "transparent",
                '& .MuiLinearProgress-bar': {
                    backgroundColor: config.colors.defaultColor
                  }
            }}/>
        </Box>
    )
}