import React, { useState } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/start.scss';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
  
  export default function StartWritePage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const opt = searchParams.get("opt");
    const redirectPath = searchParams.get("redirect");
  
    return (
      <div className="start">
        <div className="container py-60px">
          <div className="logo mx-auto mb-4">
            <Link to={`/`}>
              <img src={imgLogo} className="w-100" alt="" />
            </Link>
          </div>
          <div className="great-msg mx-auto">
            Great! Let's get you set up to write first.
          </div>
          <div className="text-card mx-auto">
            Are you writing stories about yourself or on behalf of another person?
          </div>
          <div className="write-options mx-auto">
            {/* <Link className="write-option btn btn-primary" to={`/start/writersurvey${opt === 'wr' ? '?opt=wr' : '' }${redirectPath ? ((opt === 'wr' ? '&' : '?') + 'redirect=' + redirectPath) : ''}`}>
              Myself
            </Link> */}
            <Link className="write-option btn btn-primary" to={`/start/success${opt === 'wr' ? '?opt=wr' : '' }${redirectPath ? ((opt === 'wr' ? '&' : '?') + 'redirect=' + redirectPath) : ''}`}>
              Myself
            </Link>
            <Link className="write-option btn btn-secondary" to={`/start/invite${opt === 'wr' ? '?opt=wr' : '' }`}>
              Someone Else
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    );
}