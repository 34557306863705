import React, { useContext } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import "../../../styles/story.scss";
import { StoryDetailBanner, StoryTag } from "../..";
import NavDropdown from "react-bootstrap/NavDropdown";
import { AuthContext } from "../../../../context";
import imgPencilIcon from "../../../../assets/images/icons/ic_pencil.svg";
import { Box, Chip, Container, Stack, Typography } from "@mui/material";
import config from "../../../../config/config";
import { convertFromRaw, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import draftToHtml from "draftjs-to-html";
import createImagePlugin from "@draft-js-plugins/image";
import createVideoPlugin from "@draft-js-plugins/video";
import createInlineToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/inline-toolbar";
import createAlignmentPlugin from "@draft-js-plugins/alignment";
import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/image/lib/plugin.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import "@draft-js-plugins/text-alignment/lib/plugin.css";
import "../../../styles/editor_rich_style.scss";
import { Helpers } from "../../../../utils";

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();
const alignmentPlugin = createAlignmentPlugin();
const textAlignmentPlugin = createTextAlignmentPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();

const plugins = [
  videoPlugin,
  imagePlugin,
  alignmentPlugin,
  textAlignmentPlugin,
  inlineToolbarPlugin,
];

export default function StoryBySingleAuthor(props) {
  const { story, chapter, chapterIndex, handlePagination } = props;
  const { storyId, storyPartId } = useParams();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { user } = useContext(AuthContext);

  return (
    <Box>
      <StoryDetailBanner
        story={story}
        chapterIndex={chapterIndex}
        handlePagination={handlePagination}
      />
      <Container maxWidth={"lg"} sx={{ padding: "20px 0" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          sx={{ mt: 6 }}
        >
          <Typography
            sx={{
              color: config.colors.primaryTextColor,
              fontSize: "28px",
              fontWeight: 500,
              fontFamily: "Libre Franklin",
              fontStyle: "normal",
            }}
          >
            {story?.title}
          </Typography>
          {story?.user?.id === user?.id ? (
            <Link
              title="Edit Chapter"
              to={`/mystories/${storyId}/write/${
                storyPartId ? storyPartId : chapter?.id
              }`}
            >
              <img src={imgPencilIcon} alt="" width={20} height={20} />
            </Link>
          ) : null}
        </Stack>
        <Typography
          sx={{
            color: config.colors.primaryTextColor,
            fontSize: "24px",
            fontWeight: 400,
            fontFamily: "Libre Franklin",
            fontStyle: "normal",
            mt: 1,
          }}
        >
          {chapter?.title}
        </Typography>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mt: 1 }}
        >
          <Box>
            {story?.tags &&
              story?.tags.split("#").map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    mr: "5px",
                    mb: "5px",
                    color: "#353535",
                    fontSize: "14px",
                    fontFamily: "Libre Franklin",
                  }}
                />
              ))}
          </Box>
          {/* <NavDropdown
            title="Skip to chapter"
            className="chapter-dropdown ms-auto"
          >
            {story?.storyParts &&
              story?.storyParts.map((item, index) => (
                <div key={index}>
                  {index === 0 ? null : <NavDropdown.Divider />}
                  <Link
                    className={`dropdown-item ${
                      index === chapterIndex ? "active" : ""
                    }`}
                    to={`/story/${story.id}/${item.id}${hash}`}
                  >
                    Chapter {index + 1}
                  </Link>
                </div>
              ))}
          </NavDropdown> */}
        </Stack>
        <Box
          mt={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            // paddingX: {
            //   xs: "5%",
            //   sm: "10%",
            //   md: "15%",
            // }
          }}
        >
          <img
            src={story?.logo}
            className=""
            alt=""
            width={"100%"}
            height={"100%"}
            style={{
              maxWidth: "500px",
              maxHeight: "300px",
              objectFit: "cover",
            }}
          />
        </Box>
        {/* <Typography
          sx={{
            mt: 3,
            mb: 2,
            fontFamily: "Libre Franklin",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px",
            color: config.colors.primaryTextColor,
          }}
        >
          Chapter {chapterIndex + 1}
        </Typography> */}
        <Box
          sx={{
            fontFamily: "Libre Franklin",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            color: config.colors.primaryTextColor,
            mt: 3,
          }}
        >
          {chapter?.content && Helpers.isJsonString(chapter?.content) ? (
            <Editor
              readOnly
              editorState={EditorState.createWithContent(
                convertFromRaw(JSON.parse(chapter?.content))
              )}
              customStyleMap={config.customStyleMap}
              plugins={plugins}
            />
          ) : (
            <Typography
              dangerouslySetInnerHTML={{
                __html: chapter?.content,
              }}
            />
          )}
        </Box>
        {/* <Typography
          sx={{
            mt: 3,
            mb: 5,
            fontFamily: "Libre Franklin",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px",
            color: config.colors.primaryTextColor,
          }}
        >
          Continue reading...
        </Typography> */}
      </Container>
    </Box>
  );
}
