import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { MainHeader, ProfileBanner, Footer, StoryListItem } from '../../components';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import imgDots from '../../../assets/images/icons/dots.svg';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import { useEffect, useContext, useState } from "react";
import { AxiosContext, DelegateContext, AuthContext, StoryContext, GuestContext } from "../../../context";
import moment from "moment";

export default function DelegateStoriesPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = useParams();
    const { setLoading } = useContext(AxiosContext);
    const { setPrevRoute, removeTempStory, setTempStoryPart } = useContext(GuestContext);
    const { delegatorInfo, delegateStories, getDelegateStoriesByUserId } = useContext(DelegateContext);
    const { token, user } = useContext(AuthContext);
    const { 
        getMyStories, myStories, setMyStories, setMyStoriesPagination, 
        updateStory, updateStoryPartsOrder, deleteStory, updateStoryPart, deleteStoryPart, 
        createStory, checkStoryNoteByStoryId  
    } = useContext(StoryContext);

    const [modalStory, setModalStory] = useState(null);

    useEffect(() => {
        if(token) {
            getDelegateStoriesByUserId(userId);
        }
        window.scrollTo(0, 0);
    }, [userId, token]);

    const handleClickDetails = (storyId) => {
        setPrevRoute(location);
        navigate(`/mystories/${storyId}`);
    }

    const handleClickNotes = (storyId) => {
        checkStoryNoteByStoryId(storyId).then(res => {
            if(res.status) {
                setPrevRoute(location);
                navigate(`/mystories/${res.data.storyId}/notes/${res.data.id}`);
            } else {
                console.log('err: ', res);
            }
        });
    }

    const handleClickStoryDelete = (storyId) => {
        setLoading(true);
        deleteStory(storyId).then(res => {
            setLoading(false);
            if(res.status) {
                getDelegateStoriesByUserId(userId);
            }
            toast(res.data);
        });
    }

    const handleClickUnpublish = (storyId) => {
        const data = {
            id: storyId,
            status: 'DRAFT'
        }
        setLoading(true);
        updateStory(data).then(res => {
            setLoading(false);
            if (res.status) {
                getDelegateStoriesByUserId(userId);
            } else {
                toast(res.data);
            }
        });
    }

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...modalStory.storyParts];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setModalStory({...modalStory, storyParts: updatedList});
        updateStoryPartsOrder({storyParts: updatedList}).then(res => {
            if(res.status) {
                getDelegateStoriesByUserId(userId);
            }
        });
    };

    const handleClickStoryPartPublishToggle = (storyPart) => {
        setLoading(true);
        updateStoryPart({...storyPart, status: (storyPart.status === 'PUBLISH' ? 'DRAFT' : 'PUBLISH')}).then(res => {
            setLoading(false);
            if (res.status) {
                getDelegateStoriesByUserId(userId);
                if(res.data.story) setModalStory(res.data.story);
            } else {
                toast(res.data);
            }
        });
    }

    const handleClickStoryPartDelete = (storyPartId) => {
        setLoading(true);
        deleteStoryPart(storyPartId).then(res => {
            setLoading(false);
            if (res.status) {
                getDelegateStoriesByUserId(userId);
                if(res.data.story) setModalStory(res.data.story);
            } else {
                toast(res.data);
            }
        });
    }

    return (
        <div className="page profile bg-white">
            <MainHeader />
            <ProfileBanner author={delegatorInfo} />
            <section className="section-stories">
                <div className="container">
                    <div className="sub-container pt-5">
                        <div className="subline" dangerouslySetInnerHTML={{
                            __html: delegatorInfo?.about
                        }}></div>
                    </div>
                    <div className="story-list sub-container">
                        {
                            (delegateStories && delegateStories.length > 0) ? 
                            delegateStories.map((item, index) =>
                                <div className="row story-item my-5" key={index}>
                                    <div className="col-12 col-sm-12 col-md-5 col-lg-3">
                                        <div className="story-img">
                                            <Link to={`/story/${item.id}`}>
                                                <img src={item.logo ? item.logo : null} className="" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-7 col-lg-9">
                                        <div className="story-content">
                                            <Link to={`/story/${item.id}`}>
                                                <h2 className="story-title">{item.title}</h2>
                                            </Link>
                                            <p className="story-detail">
                                                {item.description}
                                            </p>
                                            <div className="d-flex align-items-center">
                                                <div className="story-date pe-2">
                                                    Updated on {moment(item.updatedAt).format('MMM DD, YYYY')}
                                                </div>
                                                <Dropdown className="action-dropdown">
                                                    <Dropdown.Toggle variant="default">
                                                        <img src={imgDots} className="" alt="..." />
                                                    </Dropdown.Toggle>
                                            
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleClickDetails(item.id)}>Details</Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            // onClick={() => setStoryNotesModal(true)}
                                                            onClick={() => handleClickNotes(item.id)}
                                                        >Notes</Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => setModalStory(item)}>Table of Contents</Dropdown.Item>
                                                        {
                                                            item.status === 'PUBLISH' ?
                                                            <>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item onClick={() => handleClickUnpublish(item.id)}>Unpublish</Dropdown.Item>
                                                            </> : null
                                                        }
                                                        {/* <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => handleClickStoryDelete(item.id)}>Delete</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <p className="text-center">There is no story.</p>
                        }
                    </div>
                </div>
            </section>
            <Footer />
            {/* Story Organizer Modal */}
            <Modal
                show={modalStory !== null}
                onHide={() => setModalStory(null)}
                backdrop="static"
                keyboard={false}
                className="story-action-modal lg-modal"
            >
                <Modal.Header className="border-0">
                    <Modal.Title>Story Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="modal-subline">
                            “{modalStory?.title}”
                        </div>
                        <p>Drag and drop chapters to reorder your story.</p>
                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                            {(provided) => (
                                <div
                                className="part-list"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                >
                                {modalStory?.storyParts.map((item, index) => (
                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                    {(provided) => (
                                        <div className="part-item"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <div className="part-preview">
            
                                            </div>
                                            <div className="part-info">
                                                <div className="part-headline">
                                                    {`Chapter ${index + 1}`}
                                                </div>
                                                <div className="part-subline">
                                                    {item.title}
                                                </div>
                                            </div>
                                            <Dropdown className="action-dropdown ms-auto">
                                                <Dropdown.Toggle variant="default">
                                                    <img src={imgDots} className="" alt="..." />
                                                </Dropdown.Toggle>
                                        
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigate(`/story/${item.storyId}/${item.id}`)}>View</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => {
                                                        setPrevRoute(location);
                                                        navigate(`/mystories/${item.storyId}/write/${item.id}?utm_source=delegates`);
                                                    }}>Edit</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => handleClickStoryPartPublishToggle(item)}>{item.status === 'PUBLISH' ? 'Unpublish' : 'Publish'}</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => handleClickStoryPartDelete(item.id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" className="btn-close-modal mx-auto"
                        onClick={() => setModalStory(null)}
                    >
                        Exit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}