import React from "react";
import { Link } from "react-router-dom";
import { StoryTag } from "../../../components";
import { Box, Grid, Stack, Typography } from "@mui/material";
import config from "../../../../config/config";

export default function StoryListItem({ item }) {
  return (
    <Grid container mt={3} spacing={4}>
      <Grid item xs={12} md={5} lg={3}>
        <Link
          to={`/story/${item.id}`}
          style={{
            textDecoration: "none",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              marginBottom: "10px",
              paddingTop: "75%",
              backgroundColor: "#D9D9D9",
              backgroundImage:
                "url('../../../../assets/images/icons/share.svg')",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={item.logo ? item.logo : null}
              className=""
              alt=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} md={7} lg={9}>
        <Link
          to={`/user/${item?.user?.id}`}
          style={{
            textDecoration: "none",
            color: config.colors.primaryTextColor,
          }}
        >
          <Typography
            sx={{
              color: "#abacb8",
              fontFamily: "Libre Franklin",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
            }}
          >
            {item?.user?.fullName ? item?.user.fullName : item?.user?.username}
          </Typography>
        </Link>
        <Link
          to={`/story/${item.id}`}
          style={{
            textDecoration: "none",
            color: config.colors.primaryTextColor,
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              lineHeight: "38px",
              letterSpacing: 0.0,
              color: config.colors.primaryTextColor,
              fontSize: "34px",
              fontStyle: "italic",
              fontFamily: "Lora",
            }}
          >
            {item?.title}
          </Typography>
        </Link>
        <Typography
          sx={{
            color: config.colors.primaryTextColor,
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "1rem",
            mt: "1rem",
          }}
        >
          {item.description}
        </Typography>
        <Stack direction={"row"}>
          {item?.tags &&
            item?.tags
              .split("#")
              .map((tag, index) => <StoryTag tag={tag} key={index} />)}
        </Stack>
      </Grid>
    </Grid>
  );
}
