import React, { useState, useContext } from 'react';
import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext, GuestContext } from '../../../context';
import { SocialAuth, Footer } from '../../components';
import config from '../../../config/config';
import '../../styles/auth.scss';

import imgStackPoem from '../../../assets/images/stackpoem.svg';
// import imgSignFacebook from '../../../assets/images/icons/sign-facebook.svg';
// import imgSignGoogle from '../../../assets/images/icons/sign-google.svg';
import imgSignEmail from '../../../assets/images/icons/sign-email.svg';
  
  export default function RegisterPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const email = searchParams.get("email");
    const theme = createTheme();
    const { register, socialAuthentication, getGoogleUserInfo } = useContext(AuthContext);
    const { setTempUserData } = useContext(GuestContext);
    const [loading, setLoading] = useState(false);

    const onResponseGoogleFailure = (response) => {
      // toast('Something is wrong');
      console.log(response);
    }

    const onResponseGoogle = async(response) => {
      const { email, family_name, given_name, id, picture, name } = response.data;
      const postData = {
        email: email,
        firstName: given_name,
        lastName: family_name,
        fullName: name,
        imageUrl: picture,
        provider: config.auth.provider.google,
        providerId: id,
      };
      
      setLoading(true);
      const user = await socialAuthentication(postData);
      setLoading(false);
      if (user.token) {
        // window.location.href = window.location.protocol + '//' + window.location.host;
        navigate('/');
      } else {
        setTempUserData(postData);
        navigate(`/auth/register/step2?mode=social${redirectPath ? '&redirect=' + redirectPath : ''}`);
      }
    }

    const onResponseFacebookFailure = (response) => {
      // toast('Something is wrong');
      console.log('== FB err ==', response);
    }

    const onResponseFacebook = async(response) => {
      console.log('== FB response ==', response);
      const { email, id, name, picture } = response;
      const postData = {
        email: email,
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1],
        fullName: name,
        imageUrl: picture?.data?.url.split('?')[0],
        provider: config.auth.provider.facebook,
        providerId: id,
      };
      
      setLoading(true);
      const user = await socialAuthentication(postData);
      setLoading(false);
      if (user.token) {
        // window.location.href = window.location.protocol + '//' + window.location.host;
        navigate('/');
      } else {
        setTempUserData(postData);
        navigate(`/auth/register/step2?mode=social${redirectPath ? '&redirect=' + redirectPath : ''}`);
      }
    }
  
    return (
      <div className="page auth">
        <div className="container">
          <div className="sub-container">
            <div className="auth-container">
              <div className="auth-banner">
                <div className="banner-img mb-5">
                  <img src={imgStackPoem} className="w-100" alt="" />
                </div>
              </div>
              <div className="auth-form mx-auto">
                <div className="auth-card">
                  <h1 className="headline">Join<span className="text-highlight pe-5"> Storymoir </span></h1>
                  <p className="auth-subline">
                    Become part of a global community of readers and writers, all connected through the power of story.
                  </p>
                  <div className="auth-options">
                    <SocialAuth 
                      onResponseGoogle={onResponseGoogle} 
                      onResponseGoogleFailure={onResponseGoogleFailure} 
                      onResponseFacebook={onResponseFacebook}
                      onResponseFacebookFailure={onResponseFacebookFailure}
                      isLogin={false}
                    />
                    <button className="btn auth-option btn-signup-email"
                      onClick={() => navigate(`/auth/register/step2${redirectPath ? '?redirect=' + redirectPath : ''}${email ? '&email=' + email : ''}`)}
                    >
                      <img src={imgSignEmail} className="ms-auto me-3" alt="" />
                      <span className="me-auto">Email</span>
                    </button>
                  </div>
                  <div className="auth-subline">
                    If you already have an account, <Link className="auth-link fw-bold" to={`/auth/login${redirectPath ? '?redirect=' + redirectPath : ''}`}>Log In</Link>
                  </div>
                  <div className="terms-line">
                    By continuing, you agree to Storymoir’s <Link className="auth-link">Terms of Service</Link> and <Link className="auth-link" to={`/privacy`}>Privacy Policy</Link>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }