import React, { createContext, useContext, useState } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';

const ShareContext = createContext();

const ShareContextProvider = (props) => {

    const { axiosInstance } = useContext(AxiosContext);

    const shareStory = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.shares,
            data
        }).then(response => {
            console.log(response.data);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    // const getSharedPeopleByStoryId = (storyId) => {
    //     return axiosInstance({
    //         method: 'GET',
    //         url: apis.shares + '/people' + storyId,
    //         data
    //     }).then(response => {
    //         console.log(response.data);
    //         return response.data;
    //     }).catch(error => {
    //         console.log('err: ', error);
    //     });
    // }

    const providerValue = {
        shareStory,
        // getSharedPeopleByStoryId,
    }

    return (
        <ShareContext.Provider value={providerValue}>
            {props.children}
        </ShareContext.Provider>
    );

}

export {
    ShareContext, ShareContextProvider
}