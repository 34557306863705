import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import imgShare from "../../../../assets/images/icons/ic_share.svg";
import imgDots from "../../../../assets/images/icons/dots.svg";
import { Box, Grid, Stack, Typography } from "@mui/material";
import config from "../../../../config/config";

export default function LibraryItem(props) {
  const {
    item,
    activeTab,
    handleClickShare,
    handleClickDetails,
    handleClickNotes,
    setModalStory,
    handleClickUnpublish,
    handleClickPublish,
    handleShare,
    handleClickStoryDelete,
  } = props;

  return (
    <Grid container mt={3} spacing={4}>
      <Grid item xs={12} md={5} lg={3}>
        <Link
          to={`/story/${item.id}`}
          style={{
            textDecoration: "none",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              marginBottom: "10px",
              paddingTop: "75%",
              backgroundColor: "#D9D9D9",
              backgroundImage:
                "url('../../../../assets/images/icons/share.svg')",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={item.logo ? item.logo : null}
              className=""
              alt=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                "object-fit": "cover",
              }}
            />
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} md={7} lg={9}>
        <Link to={`/story/${item.id}`} style={{ textDecoration: "none", color: config.colors.primaryTextColor }}>
          <Typography
            sx={{
              fontWeight: "600",
              lineHeight: "38px",
              letterSpacing: 0.0,
              color: config.colors.primaryTextColor,
              fontSize: "34px",
              fontStyle: "italic",
              fontFamily: "Lora",
            }}
          >
            {item?.title}
          </Typography>
        </Link>
        <Typography sx={{
          color: config.colors.primaryTextColor,
          fontFamily: "Libre Franklin",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",
          marginBottom: "1rem",
          mt: "1rem"
        }}>{item.description}</Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"start"}
          spacing={2}
        >
        <Typography variant="body1" >
        {activeTab === "published" ? "Published" : "Updated"} on{" "}
            {moment(item.updatedAt).format("MMM DD, YYYY")}
        </Typography>
          {/* {item.status === "PUBLISH" ? (
              <button
                className="btn btn-share"
                onClick={() => handleClickShare(item)}
              >
                <img src={imgShare} className="" alt="" />
              </button>
            ) : null} */}
          <Dropdown className="action-dropdown">
            <Dropdown.Toggle variant="default">
              <img src={imgDots} className="" alt="..." />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleClickDetails(item)}>
                Details
              </Dropdown.Item>
              <Dropdown.Divider />
              {/* <Dropdown.Item onClick={() => handleClickNotes(item)}>
                Notes
              </Dropdown.Item>
              <Dropdown.Divider /> */}
              <Dropdown.Item onClick={() => setModalStory(item)}>
                Table of Contents
              </Dropdown.Item>
              {activeTab === "saved_stories" ? <>
                    {/* <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleClickUnpublish(item)}>
                      Remove from Saved Stories
                    </Dropdown.Item> */}
                  </> : <>{
                item.status === "PUBLISH" ? (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleClickUnpublish(item)}>
                      Unpublish
                    </Dropdown.Item>
                  </>
                ) : <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleClickPublish(item)}>
                  Publish
                </Dropdown.Item>
              </>
              }</>}
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  handleShare(item);
                }}
              >
                Share
              </Dropdown.Item>
              {activeTab !== "saved_stories" && <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleClickStoryDelete(item)}>
                Delete
              </Dropdown.Item>
              </>}
              
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </Grid>
    </Grid>
  );
}
