import React, { useEffect } from "react";
import FacebookLogin from 'react-facebook-login';
import { Facebook, Google } from '@mui/icons-material';
import config from '../../../config/config';
import '../../styles/custom_style.css';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export default function SocialAuth(props) {

    useEffect(() => {

    }, []);

    const loginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => getGoogleProfile(tokenResponse),
        onError: error => console.log('Google Login Failed:', error)
    });

    const getGoogleProfile = (tokenResponse) => {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
            headers: {
                Authorization: `Bearer ${tokenResponse.access_token}`,
                Accept: 'application/json'
            }
        }).then((res) => {
            console.log('res: ', res);
            if(res.data) {
                props.onResponseGoogle(res);
            }
        }).catch((err) => {
            console.log('err: ', err);
            props.onResponseGoogleFailure(err);
        });
    }

    return (
        <div className="auth-options">
            <FacebookLogin
                appId={config.social.facebook.appId}
                textButton={props.isLogin ? "Facebook" : "Facebook"}
                // fields="name,email,picture"
                // scope="public_profile"
                callback={props.onResponseFacebook}
                onFailure={props.onResponseFacebookFailure}
                cssClass="btn auth-option btn-signup-facebook"
                icon={<Facebook sx={{ marginRight: '10px', color: '#FFFFFF' }} />}
            />
            <button className="btn auth-option btn-signup-google" onClick={() => loginWithGoogle()}>
                <Google sx={{ marginRight: '10px', color: '#FFFFFF' }} />
                {props.isLogin ? "Google" : "Google"}
            </button>
        </div>
    );
}