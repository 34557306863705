import React, { useContext, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Stack, Avatar, Box, Typography } from '@mui/material';
import config from '../../../config/config';
import { PrimaryButton, PrimaryInput } from '../../components';
import { toast } from 'react-toastify';
import { AdminContext } from '../../../context';
import { useNavigate } from 'react-router';

export default function AdminLoginPage() {

  const navigate = useNavigate();

  const {adminLogin} = useContext(AdminContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async() => {
    if (!formData.email || !formData.password) {
      toast.warn("Please enter email and password");
    } else {
      setLoading(true);
      const result =  await adminLogin(formData);
      setLoading(false);
      if (result.status) {
        // navigate("/admin/template-lists");
        window.location.href = "/admin/template-lists";
      } else {
        toast.warn(result?.message || "Invalid credential");
      }
    }
  };

  return (
    <Box>
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: "100vh",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: config.colors.primaryTextColor }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Stack
           sx={{ mt: 1, pt: 2, }}
           direction={"column"}
           spacing={2}
           >
            <PrimaryInput
              type={"email"}
              placeholder={"your email"}
              value={formData.email}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              sx={{
                minWidth: 400,
              }}
            />
            <PrimaryInput
              type={"password"}
              placeholder={"enter password"}
              value={formData.password}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  password: e.target.value,
                });
              }}
            />
            <Box
            sx={{ pt: 3, pb: 2 }}
            >
            <PrimaryButton
              type={"submit"}
              label={"Log In"}
              disabled={loading}
              width={"100%"}
              handleClick={handleSubmit}
            />
            </Box>
          </Stack>
        </Stack>
    </Box>
  );
}