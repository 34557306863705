import { useEffect, useState } from 'react';
import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles';

import LibreFranklin from "../../assets/fonts/LibreFranklin/LibreFranklin-VariableFont_wght.ttf";
import Lora from "../../assets/fonts/Lora/Lora-VariableFont_wght.ttf";
import LoraItalic from "../../assets/fonts/Lora/Lora-Italic-VariableFont_wght.ttf";
import PTSerif from "../../assets/fonts/PT_Serif/PTSerif-Regular.ttf";


const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#DB805E",
    },
  },
  typography: {
    fontFamily: 'LibreFranklin, Lora, PTSerif',
      textTransform: "none",
      color: "#141A46",
    allVariants: {
      textTransform: "none",
      color: "#141A46",
    },
    button: {
      textTransform: "none",
      color: "#141A46",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lora';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Lora'), local('Lora-Regular'), url(${Lora}) format('ttf');
        },
        @font-face {
          font-family: 'Lora';
          font-style: italic;
          font-display: swap;
          font-weight: 500;
          src: local('Lora'), local('Lora-Regular'), url(${LoraItalic}) format('ttf');
        },
        @font-face {
          font-family: 'Libre Franklin';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Libre Franklin'), local('Libre Franklin-Regular'), url(${LibreFranklin}) format('ttf');
        },
        @font-face {
          font-family: 'PT Serif';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('PT Serif'), local('PT Serif-Regular'), url(${PTSerif}) format('ttf');
        }
      `,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536,
    },
  },
});

export default function MainLayout({ children }) {
  const [showGoTop, setShowGoTop] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
  }, []);

  const handleScrollEvent = (e) => {
    if(e.target.documentElement.scrollTop > 0) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {children}
      <button className={`btn-page-up ${!showGoTop ? 'hidden' : ''}`}
        onClick={() => window.scrollTo(0, 0)}
      ></button>
    </ThemeProvider>
  );
}