import React from "react";
import { RichUtils, Modifier, EditorState } from 'draft-js';
import {
  toggleCustomInlineStyle, getSelectionCustomInlineStyle,
} from 'draftjs-utils';
import { FormatBold, FormatItalic, FormatUnderlined } from '@mui/icons-material';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { Box } from "@mui/material";
import StyleButton from "../buttons/style_button";
import config from "../../../config/config";

const INLINE_STYLES = [
  { label: "inline", icon: <FormatBold fontSize='small' sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "BOLD" },
  { label: "inline", icon: <FormatItalic fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "ITALIC" },
  { label: "inline", icon: <FormatUnderlined fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "UNDERLINE" },
];

const BLOCK_TYPES = [
  { label: "align-left", icon: <FormatAlignLeftIcon fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "left" },
  { label: "align-center", icon: <FormatAlignCenterIcon fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "center" },  
  { label: "align-number", icon: <FormatListNumberedIcon fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "unordered-list-item" },    
];

const FONT_TYPES = [  
  { label: "text-increase", icon: <TextIncreaseIcon fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: 2 },    
  { label: "text-decrease", icon: <TextDecreaseIcon fontSize='small'  sx={{color: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: -2 },
  // { label: "text-decrease", icon: <FontDownloadIcon fontSize='small'  sx={{color: config.colors.primaryTextColor, bgcolor: config.colors.bgWhiteColor, cursor: 'pointer'}}/>, style: "FONTSIZE" },
];

export default function EditorTools(props) {
  const {editorState, setEditorState, fontSize, setFontSize} = props;   
  const selection = editorState.getSelection(); 
  const currentStyle = editorState.getCurrentInlineStyle();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const fontAction = (e, label) => {
    const size = parseInt(fontSize.split('-')[1]) + e;
    console.log(size);
    setFontSize(`fontsize-${size}`);
    setEditorState(toggleCustomInlineStyle(editorState, 'fontSize', size));
    // setEditorState(RichUtils.toggleBlockType(editorState, "40px"));
  };

  const inlineAction = (e, label) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, e));
  }

  const blockAction = (e, label) => {
    setEditorState(RichUtils.toggleBlockType(editorState, e));
    // if (e == "unordered-list-item") {
    //   setEditorState(RichUtils.toggleBlockType(editorState, e));
    // } else {
    //   setEditorState(RichUtils.toggleInlineStyle(editorState, "CENTER"));
    // }
  }

  return (
    <Box
      sx={{
        bgcolor: config.colors.primaryTextColor,
        borderRadius: 1,
      }}
    >
      {INLINE_STYLES.map((type, i) => (
        <StyleButton
          key={`inline-style-${i}`}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          handleClick={inlineAction}
          style={type.style}
        />
      ))}
      {FONT_TYPES.map((type, i) => (
        <StyleButton
          key={`font-style-${i}`}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          handleClick={fontAction}
          style={type.style}
        />
      ))}
      {BLOCK_TYPES.map((type, i) => (
        <StyleButton
        key={`block-style-${i}`}
        icon={type.icon}
        handleClick={blockAction}
        label={type.label}
        style={type.style}
        active={type.style === blockType}
        />
      ))}
    </Box>
  );
}