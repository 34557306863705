import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/style.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import config from "./config/config";
import { ContextProvider } from "./context";
import { GoogleOAuthProvider } from "@react-oauth/google";


ReactDOM.render(
  <BrowserRouter basename={config.basename}>
    <GoogleOAuthProvider clientId={config.social.google.clientId}>
    <ContextProvider>
          <App />
        </ContextProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
