import React, { useState } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/start.scss';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
import imgWriteOption from '../../../assets/images/icons/opt-write.svg';
import imgReadOption from '../../../assets/images/icons/opt-read.svg';
import imgBothOption from '../../../assets/images/icons/opt-both.svg';
  
  export default function StartPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
  
    return (
      <div className="start">
        <div className="container py-60px">
          <div className="logo mx-auto mb-4">
            <Link to={`/`}>
              <img src={imgLogo} className="w-100" alt="" />
            </Link>
          </div>
          <div className="text-card mx-auto">
            Do you want to write stories or read stories?
          </div>
          <div className="start-options mx-auto">
            <Link className="start-option" to={`/start/write${redirectPath ? '?redirect=' + redirectPath : ''}`}>
              <div className="option-img">
                <img src={imgWriteOption} alt="" />
              </div>
              <div className="option-text">
                I want to<span className="text-highlight sm-rule text-primary"> write </span>stories.
              </div>
            </Link>
            {/* <Link className="start-option" to={`/start/readersurvey${redirectPath ? '?redirect=' + redirectPath : ''}`}>
              <div className="option-img">
                <img src={imgReadOption} alt="" />
              </div>
              <div className="option-text">
                I'd like to<span className="text-highlight sm-rule text-primary"> read </span>stories.
              </div>
            </Link> */}
            <Link className="start-option" to={`/start/success${redirectPath ? '?redirect=' + redirectPath : ''}`}>
              <div className="option-img">
                <img src={imgReadOption} alt="" />
              </div>
              <div className="option-text">
                I'd like to<span className="text-highlight sm-rule text-primary"> read </span>stories.
              </div>
            </Link>
            <Link className="start-option" to={`/start/write?opt=wr${redirectPath ? '&redirect=' + redirectPath : ''}`}>
              <div className="option-img">
                <img src={imgBothOption} alt="" />
              </div>
              <div className="option-text">
                I want to do<span className="text-highlight sm-rule text-primary"> both </span>.
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    );
}