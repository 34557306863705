import React, { useContext, useEffect } from 'react';
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/auth.scss';
import { GuestContext } from '../../../context';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
  
  export default function StartSuccessPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setPrevRoute } = useContext(GuestContext);
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");

    useEffect(() => {
      if (redirectPath) {
        navigate(redirectPath);
      }
    }, [redirectPath]);
  
    return (
      <div className="page auth bg-flying-books">
        <div className="container py-60px">
          <div className="auth-container">
            <div className="auth-form mx-auto">
              <div className="auth-card text-center">
                <div className="logo mx-auto mb-4">
                  <Link to={`/`}>
                    <img src={imgLogo} className="w-100" alt="" />
                  </Link>
                </div>
                {/* <h1 className="headline"><span className="text-highlight px-3">All done</span></h1> */}
                <p className="auth-subline my-5 mx-auto text-center">
                  Thanks for setting up your profile!
                  <br />
                  A world of stories awaits you...
                </p>
                <div className="start-options">
                  <Link className="btn btn-primary btn-start-writing ms-auto"
                    to="/mystories/new"
                  >Start Writing</Link>
                  <Link className="btn border btn-start-reading me-auto"
                    to="/stories"
                  >Start Reading</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }