import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/start.scss';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
import { AuthContext, SurveyContext, AxiosContext } from '../../../context';
  
  export default function ReaderSurveyPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const { readerSurveys, getReaderSurveys, createOrUpdateSurveyResults } = useContext(SurveyContext);
    const { setLoading } = useContext(AxiosContext);
    const { user } = useContext(AuthContext);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        getReaderSurveys().then(res => {
          if(res.status) {
            const data = [];
            for (let i = 0; i < res.data.length; i++) {
              data.push({
                ...res.data[i],
                user_id: user?.id,
                answer: '',
                is_show: true
              });
            }
            console.log('==== ', data);
            setFormData(data);
          }
        });
    }, [user]);

    const handleFormChange = (index, column, value, inputType) => {
        console.log('---1---', index, column, value, inputType);
        let _value = value;
        if(inputType === 'checkbox') {
            _value = formData[index][column];
            console.log('===', _value);
            if (_value.includes(value)) {
                _value = _value.replace(value, '').split(',').filter(ele => ele !== '').join(',');
            } else {
                _value += _value ? (',' + value) : value;
            }
        }
        const newFD = formData.map((obj, i) => {
          if (i === index) {
            return {
              ...obj,
              [column]: _value
            }
          }
          return obj
        });
        console.log('---2---', newFD);
        setFormData(newFD);
    }
  
    const handleSubmit = () => {
        setLoading(true);
        createOrUpdateSurveyResults({surveys: formData}).then((res) => {
          setLoading(false);
          if(res.status) {
            navigate(`/start/success${redirectPath ? '?redirect=' + redirectPath : ''}`)
          }
        });
    }
  
    return (
      <div className="start">
        <div className="container py-60px">
            <div className="logo mx-auto mb-4">
                <Link to={`/`}>
                    <img src={imgLogo} className="w-100" alt="" />
                </Link>
            </div>
            <div className="thanks-msg mx-auto">
                Awesome, we hope find some incredible stories here.
                <br />
                Before we go further, we'd love to know more about what types of stories you're looking for
            </div>
            <p className="survey-subline mx-auto">
                All this info is optional, but it will help us show your stories to the people who're looking for them.
            </p>
            <div className="survey-form mx-auto mt-5">
                {
                    formData && formData.map((item, index) => 
                        {
                            if(item.type === 'date' || item.type === 'text') {
                                return (
                                    <div className="row" key={index}>
                                        <div className="col-12 col-sm-12 col-md-8">
                                            <div className="inline-group-sm mx-auto">
                                                <div className="row w-100">
                                                    <div className="col-4 d-flex align-items-center justify-content-end">
                                                        <label className="sub-label">
                                                        {item.question}
                                                        </label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input type={item.type} className="form-control" name={item.id} placeholder={item.question} 
                                                        value={item.answer} 
                                                        onChange={(e) => handleFormChange(index, 'answer', e.target.value, item.type)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if(item.type === 'checkbox') {
                                return (
                                <div className="row" key={index}>
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <div className="form-group">
                                            <label className="form-label">{item.question}</label>
                                            {
                                                item.ansOptions && item.ansOptions.split(',').map((option, optIndex) =>
                                                    <div className="inline-group" key={optIndex}>
                                                        <input type="checkbox" className="form-checkbox" 
                                                            checked={item.answer.includes(option) ? true : false} 
                                                            value={item.answer}
                                                            onChange={(e) => handleFormChange(index, 'answer', option, item.type)}
                                                        />
                                                        {option}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                )
                            } else if(item.type === 'textarea') {
                                return (
                                <div className="form-group" key={index}>
                                    <label className="form-label">{item.question}</label>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8">
                                            <textarea className="form-textarea mb-3" rows={7}
                                            value={item.answer} 
                                            onChange={(e) => handleFormChange(index, 'answer', e.target.value, item.type)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        }
                    )
                }
                
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary btn-submit"
                            onClick={() => handleSubmit()}
                        >Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </div>
    );
}