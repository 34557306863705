import React from "react";
import { Button } from '@mui/material';
import config from "../../../config/config";

export default function TextButton(props) {

    const {
        handleClick,
        label,
        width = "100%",
        height = 50,
        startIcon,
        sx
    } = props;

    return <Button
    onClick={handleClick}
    variant="contained"
    sx={{
        textTransform: "none",
        bgcolor: "transparent",
        fontFamily: "Libre Franklin",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "30px",
        color: config.colors.defaultColor,
        borderColor: "transparent",
        borderRadius: "0",
        boxShadow: "none",
        "&:hover": {
            bgcolor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
        },
        "&:disabled": {
            bgcolor: "transparent",
            borderColor: "transparent",
            opacity: 0.5,
            boxShadow: "none",
        },
        height: height,
        width: width,
        ...sx,
    }}
    startIcon={startIcon ? startIcon : null}
    >
        {label}
    </Button>;
}