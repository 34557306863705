// eslint-disable-next-line import/no-anonymous-default-export
export default {
    baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api/' : 'https://storymoir.com/api/',
    // baseUrl: 'https://storymoir.com/api/',
    // baseUrl: 'http://ec2-18-118-33-210.us-east-2.compute.amazonaws.com/api/',
    login: 'auth/login',
    register: 'auth/register',
    forgotPassword: 'forgot-password',
    socialAuth: 'auth/socialAuth',
    uploadImage: 'upload/image',
    uploadVideo: 'upload/video',
    story: 'story',
    stories: 'stories',
    mystories: 'mystories',
    storyParts: 'storyParts',
    users: 'users',
    myInfo: 'users/my',
    categories: 'categories',
    search: 'search',
    surveys: 'surveys',
    surveyResults: 'surveyResults',
    shares: 'shares',
    services: 'services',
    delegate: 'delegate',
    waitingList: 'waiting-list',

    adminLogin: 'admin/auth/login',
    adminSendGridEmails: 'admin/send-grid/emails',
    adminWaitingUsers: 'admin/waiting-users',
};