import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext, AxiosContext, GuestContext } from '../../../context';
import '../../styles/auth.scss';
import { Footer } from '../../components';

import imgPolaroids from '../../../assets/images/polaroids.svg';
  
  export default function RegisterStep2Page() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const email = searchParams.get("email");
    const mode = searchParams.get("mode");
    const { register, socialAuth, logout } = useContext(AuthContext);
    const { setLoading } = useContext(AxiosContext);
    const { getTempUserData, removeTempUserData } = useContext(GuestContext);

    const [pwdShow, setPwdShow] = useState({
      password: false,
      conPassword: false
    });
    const [dob, setDob] = useState({
      date: '00',
      month: '00',
      year: '0000'
    });
    const [formData, setFormData] = useState({
      username: '',
      email: '',
      password: '',
      conPassword: '',
      dob: '',
      provider: 'EMAIL'
    });

    useEffect(() => {
      initFormData();
    }, [mode, email]);

    const initFormData = () => {
      if(mode === 'social') {
        const _postData = getTempUserData();
        setFormData({...formData, username: _postData.email, email: _postData.email});
      } else {
        setFormData({...formData, email: (!!email ? email : '')});
      }
    }

    const handleSubmit = async () => {
      const postData = {...formData, dob: dob.year + '-' + dob.month + '-' + dob.date};
      if(mode === 'social') {
        const _postData = getTempUserData();
        postData.firstName = _postData.firstName;
        postData.lastName = _postData.lastName;
        postData.fullName = _postData.fullName;
        postData.imageUrl = _postData.imageUrl;
        postData.provider = _postData.provider;
        postData.providerId = _postData.providerId;
      }
      // console.log('>>>', postData);
      setLoading(true);
      // logout
      logout();
      // 
      const user = await register(postData);
      console.log('===', user);
      setLoading(false);
      if (user.token) {
        removeTempUserData();
        // window.location.href = window.location.protocol + '//' + window.location.host;
        navigate(`/start${redirectPath ? '?redirect=' + redirectPath : ''}`);
      } else {
        toast(user);
      }
    }
  
    return (
      <div className="page auth">
        <div className="container">
          <div className="auth-container">
            <div className="auth-banner">
              <div className="banner-img mb-5">
                <img src={imgPolaroids} className="w-100" alt="" />
              </div>
            </div>
            <div className="auth-form mx-auto">
              <div className="auth-card">
                <h1 className="headline">Join<span className="text-highlight pe-5"> Storymoir </span></h1>
                <p className="auth-subline">
                  Become part of a global community of readers and writers, all connected through the power of story.
                </p>
                <form className="form-wrapper">
                  <div className="form-group">
                    <label>Username</label>
                    <div className="form-control-wrapper">
                      <input type="text" className="form-control" placeholder="MG-tester" 
                        name="username" 
                        autoComplete="off" 
                        value={formData.username}
                        onChange={(e) => setFormData({...formData, username: e.target.value})}
                      />
                      {/* <span className="form-check-icon"></span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-control-wrapper">
                      <input type="email" className="form-control" placeholder="iam@mikegrossman.com" 
                        name="email" 
                        autoComplete="off" 
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                      />
                      {/* <span className="form-check-icon"></span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <div className="form-control-wrapper">
                      <input type={pwdShow.password ? 'text' : 'password'} className="form-control" placeholder="New Password" 
                        name="password" 
                        autoComplete="off" 
                        value={formData.password} 
                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                      />
                      <span className="toggle-show cursor-pointer"
                        onClick={() => setPwdShow({...pwdShow, password: !pwdShow.password})}
                      >{pwdShow.password ? 'Hide' : 'Show'}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="form-control-wrapper">
                      <input type={pwdShow.conPassword ? 'text' : 'password'} className="form-control" placeholder="Confirm Password" 
                        name="conPassword" 
                        autoComplete="off" 
                        value={formData.conPassword} 
                        onChange={(e) => setFormData({...formData, conPassword: e.target.value})}
                      />
                      <span className="toggle-show cursor-pointer"
                        onClick={() => setPwdShow({...pwdShow, conPassword: !pwdShow.conPassword})}
                      >{pwdShow.conPassword ? 'Hide' : 'Show'}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Birthday</label>
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4">
                        <div className="form-control-wrapper">
                          <select className="form-control mb-2" 
                            value={dob.month}
                            onChange={(e) => setDob({...dob, month: e.target.value})}
                          >
                            <option value="00">Month</option>
                            <option value="01">Jan</option>
                            <option value="02">Feb</option>
                            <option value="03">Mar</option>
                            <option value="04">Apr</option>
                            <option value="05">May</option>
                            <option value="06">Jun</option>
                            <option value="07">Jul</option>
                            <option value="08">Aug</option>
                            <option value="09">Sep</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4">
                        <div className="form-control-wrapper">
                          <select className="form-control mb-2"
                            value={dob.date}
                            onChange={(e) => setDob({...dob, date: e.target.value})}
                          >
                            <option value="00">Day</option>
                            {
                              Array.from(Array(31), (e, i) => <option key={i} value={i > 8 ? (i+1) : '0' + (i+1)}>{i > 8 ? (i+1) : '0' + (i+1)}</option>)
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4">
                        <div className="form-control-wrapper">
                          <select className="form-control mb-2"
                            value={dob.year}
                            onChange={(e) => setDob({...dob, year: e.target.value})}
                          >
                            <option value="0000">Year</option>
                            {
                              Array.from(Array(100), (e, i) => <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-secondary btn-back mt-4"
                      type="button"
                      // onClick={() => navigate(`/auth/register/step1${redirectPath ? '?redirect=' + redirectPath : ''}`)}
                      onClick={() => navigate(`/auth/register${redirectPath ? '?redirect=' + redirectPath : ''}`)}
                    >Back</button>
                    <button className="btn btn-primary btn-register mt-4"
                      type="button"
                      onClick={() => handleSubmit()}
                    >Sign Up</button>
                  </div>
                </form>
                <div className="terms-line mb-4">
                  By continuing, you agree to Storymoir’s <Link className="auth-link">Terms of Service</Link> and <Link className="auth-link">Privacy Policy</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }