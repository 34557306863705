import iconTwitter from "../../../assets/images/icons/ic_twitter.svg";
import iconFacebook from "../../../assets/images/icons/ic_facebook.svg";
import iconLinkedin from "../../../assets/images/icons/ic_linkedin.svg";
import iconLink from "../../../assets/images/icons/ic_link.svg";
import iconShare from "../../../assets/images/icons/ic_share.svg";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import config from "../../../config/config";
import { useContext } from "react";
import { GuestContext } from "../../../context";
import ReadingProgressBar from "../header/reading_progress_bar";

export default function StoryDetailBanner(props) {
  const {
    story,
    chapterIndex,
    handlePagination,
    showProgressBar = false,
  } = props;
  const { isScrolling } = useContext(GuestContext);

  return (
    <Box
      sx={{
        position: isScrolling ? "fixed" : "absolute",
        top: isScrolling ? "93px" : "123px",
        width: "100%",
        bgcolor: config.colors.secondBgColor,
        // boxShadow: isScrolling ? "0px 2px 5px 0 rgba(71,52,130,.1)" : "none",
        zIndex: 2,
        pt: "20px",
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={4}
          >
            {/* <img src={story?.logo} alt="" height="190px" width="180px" style={{objectFit: "cover"}}/> */}
            <Box>
              <Typography
                sx={{
                  color: config.colors.primaryTextColor,
                  fontSize: "24px",
                  fontFamily: "Libre Franklin",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {story?.title}
              </Typography>
              <Typography
                sx={{
                  color: config.colors.primaryTextColor,
                  fontSize: "16px",
                  fontFamily: "Libre Franklin",
                  fontStyle: "normal",
                  marginBottom: "15px",
                }}
              >
                by{" "}
                {story?.user?.fullName
                  ? story?.user?.fullName
                  : story?.user?.username}
              </Typography>
              {/* <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography sx={{
                    color: config.colors.primaryTextColor,
                    fontSize: "20px",
                    fontFamily: "Libre Franklin",
                    fontStyle: "normal",
                    cursor: "pointer",
                }}
                onClick={() => handlePagination(-1)}
                >Previous Chapter</Typography>
                <Divider orientation="vertical" flexItem sx={{width: "2px", bgcolor: config.colors.primaryTextColor}}/>
                <Typography sx={{
                    color: config.colors.primaryTextColor,
                    fontSize: "20px",
                    fontFamily: "Libre Franklin",
                    fontStyle: "normal",
                    cursor: "pointer",
                }} onClick={() => handlePagination(1)}>Next Chapter</Typography>
              </Stack> */}
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"start"}
            justifyContent={"start"}
            pt={"8px"}
          >
            <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
              <img
                src={iconTwitter}
                className=""
                alt="twitter"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
              <img
                src={iconFacebook}
                className=""
                alt="facebook"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
              <img
                src={iconLinkedin}
                className=""
                alt="linkedin"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
              <img
                src={iconLink}
                className=""
                alt="link"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
              <img
                src={iconShare}
                className=""
                alt="Share"
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      {isScrolling && showProgressBar && <ReadingProgressBar />}
    </Box>
  );
}
