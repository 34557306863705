import React, { useContext, useState } from "react";
import { Box, Card, Stack } from "@mui/material";
import { AdminLayout } from "../../layouts";
import { AdminContext } from "../../../context/admin_context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { PrimaryButton, PrimaryInput } from "../../components";

export default function WaitingUsersCreate() {
  const navigate = useNavigate();

  const { createWaitingUser } = useContext(AdminContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [showError, setShowError] = useState(false);

  const submit = async () => {
    if (!formData.name || !formData.email) {
      setShowError(true);
    } else {
      setLoading(true);
      await createWaitingUser(formData);
      setLoading(false);
      toast.success("Successfully created!");
      navigate("/admin/waiting-users");
    }
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Card
          sx={{
            p: 2,
          }}
        >
          <Stack
            direction={"column"}
            spacing={3}
            sx={{
              maxWidth: "500px",
            }}
          >
            <PrimaryInput
              label={"Name"}
              labelFontSize={"18px"}
              type="text"
              name={"name"}
              value={formData.name}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  name: e.target.value,
                });
              }}
              error={showError}
              sx={{
                height: 50,
                width: "100%",
              }}
            />
            <PrimaryInput
              label={"Email"}
              labelFontSize={"18px"}
              type="email"
              name={"email"}
              value={formData.email}
              handleChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              error={showError}
              sx={{
                height: 50,
                width: "100%",
              }}
            />
            <PrimaryButton
              label={"Create"}
              sx={{
                width: {
                  sm: 140,
                },
                height: {
                  sm: 50,
                  xs: 40,
                },
                mt: {
                  sm: 5,
                  xs: 1,
                },
              }}
              disabled={loading}
              handleClick={() => {
                submit();
              }}
            />
          </Stack>
        </Card>
      </Box>
    </AdminLayout>
  );
}
