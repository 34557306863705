import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DelegateContext } from "../../context";

export default function InvitePage() {
    const navigate = useNavigate();
    const { inviteToken } = useParams();
    const { checkInviteToken } = useContext(DelegateContext);
    const [message, setMessage] = useState('');

    useEffect(() => {
        checkInvite();
    }, []);

    const checkInvite = () => {
        checkInviteToken(inviteToken).then(res => {
            console.log('res ===', res);
            if (res.status) {
                const action = res.data.action;
                const redirect = res.data.redirect;
                const email = res.data.email;
                if (action) {
                    navigate(`/auth/${action}?redirect=${redirect}${!!email ? '&email='+email : ''}`);
                } else {
                    navigate(redirect);
                }
            } else {
                setMessage(res.data);
            }
        });
    }

    return(
        <div className="d-flex align-items-center justify-content-center" style={{height: '75vh'}}>
            {
                message && 
                <div className="card w-100 shadow" style={{maxWidth: '300px'}}>
                    <div className="card-header">Error!</div>
                    <div className="card-body">
                        {message}
                    </div>
                </div>
            }
        </div>
    );
}