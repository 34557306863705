import React, { useState } from 'react';
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Footer } from '../../components';
import '../../styles/start.scss';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
import imgMsgSent from '../../../assets/images/msg-sent.svg';
  
  export default function WriterInviteSuccessPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const opt = searchParams.get("opt");
  
    return (
      <div className="start">
        <div className="container py-60px">
          <div className="logo mx-auto mb-4">
            <Link to={`/`}>
              <img src={imgLogo} className="w-100" alt="" />
            </Link>
          </div>
          <div className="msg-sent-img mx-auto mb-4">
            <img src={imgMsgSent} className="w-100" alt="" />
          </div>
          <div className="great-msg mx-auto mt-1">
            Email Sent!
          </div>
          <div className="w-100 d-flex">
            <button className="btn btn-primary btn-ok mx-auto"
              onClick={() => navigate(`/start/writersurvey${opt === 'wr' ? '?opt=wr' : '' }`)}
            >Ok</button>
          </div>
        </div>
        <Footer />
      </div>
    );
}