import React from 'react';
import { Link } from "react-router-dom";
import imgAuthor from '../../../../assets/images/avatar.svg';

export default function UserListItem({item}) {

    return (
        <div className="user-list-item my-5">
            <div className="user-photo">
                <div className="user-photo-wrapper">
                    <img src={item?.imageUrl ? item?.imageUrl : imgAuthor} className="w-100 h-100 object-fit-cover" alt="" />
                </div>
            </div>
            <div className="user-info pt-3">
                <Link className="user-name text-decoration-none" to={`/user/${item.id}`}>{ item?.fullName ? item?.fullName : item?.username }</Link>
                <div className="user-followers mb-1">
                    {item?.followerUsers ? item?.followerUsers.length : 0} Followers  •  {item?.followingUsers ? item?.followingUsers.length : 0} Following
                </div>
                <div className="user-about">
                    {item?.about}
                </div>
            </div>
        </div>
    );
}