import React, { useRef } from "react";
import Modal from '@mui/material/Modal';
import { useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import config from "../../../config/config";
import PrimaryButton from "../buttons/primary_button";
import DefaultButton from "../buttons/default_button";
import { toast } from "react-toastify";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function VideoUploadingModal(props) {
  const {
    isOpenModal = false,
    closeModal,
    onSubmit,
  } = props;
  const [file, setFile] = useState(null);
  const ref = useRef();

  const submit = () => {
    if (file) {
      onSubmit(file);
      closeModal();
    }
  };

  const uploadVideoChange = (e) => {
    const items = e.target.files;
    if (items.length > 0) {
      const item = items[0];
      if (item?.size > 50 * 1024 * 1024) {
        toast.warn("Please upload less than 50M");
        setFile(null);
        ref.current.value = "";
      } else {
        setFile(item);
      }
    }
  };

  return (
    <Modal
    open={isOpenModal}
      onClose={closeModal}
      aria-labelledby="Video Uploading Modal"
    >
      <Box sx={style}>
      <Typography variant="h6" sx={{fontWeight: 500}}>
      Video Upload
      </Typography>
      <Typography variant="caption" sx={{color: "red"}}>
        Video must be smaller than 50MB.
      </Typography>
      <Box sx={{marginY: 3,}}>
        <TextField
          type="file"
          accept="video/mp4"
          margin="none"
          size="small"
          variant="outlined"
          fullWidth
          onChange={uploadVideoChange}
          ref={ref}
        />
      </Box>
      <Stack direction={"row"} mt={3} spacing={2} alignItems={"center"} justifyContent="end">
        <PrimaryButton
          label="Submit"
          disabled={false}
          handleClick={() => submit()}
        />
        <DefaultButton
          label="Cancel"
          handleClick={closeModal}
        />
      </Stack>
      </Box>
    </Modal>
  );
}
