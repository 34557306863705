import { useParams } from "react-router-dom";
import {
  MainHeader,
  ProfileBanner,
  Footer,
  StoryListItem,
} from "../../components";
import "../../styles/story.scss";

import { useEffect, useContext } from "react";
import { AxiosContext, UserContext, AuthContext } from "../../../context";
import { Box, Container, Typography } from "@mui/material";
import config from "../../../config/config";

export default function ProfilePage() {
  const { userId } = useParams();
  const { setLoading } = useContext(AxiosContext);
  const { getUser, getMyProfile, userDetail } = useContext(UserContext);
  const { token, user } = useContext(AuthContext);

  useEffect(() => {
    if (token && userId == user?.id) {
      getMyProfileInfo();
    } else {
      getUserDetailInfo(userId);
    }
    window.scrollTo(0, 0);
  }, [userId, token]);

  const getMyProfileInfo = () => {
    setLoading(true);
    getMyProfile().then((res) => {
      setLoading(false);
    });
  };

  const getUserDetailInfo = (userId) => {
    setLoading(true);
    getUser(userId).then((res) => {
      setLoading(false);
    });
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      <MainHeader />
      <ProfileBanner author={userDetail} />
      <Container maxWidth="lg" sx={{ pt: "0px", pb: "60px" }}>
        {/* {user?.id == userId && <Box>
          {userDetail?.stories && userDetail?.stories.length > 0 ? (
            userDetail?.stories.map((item, index) => (
              <StoryListItem item={item} key={index} />
            ))
          ) : (
            <Typography textAlign={"center"} sx={{mt: 2}} variant="body1">There is no published story.</Typography>
          )}
        </Box>} */}
      </Container>
      <Footer />
    </Box>
  );
}
