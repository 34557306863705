import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { AdminLayout } from "../../layouts";
import { AdminContext } from "../../../context/admin_context";
import { DeleteWaitingUserConfirmModal, PrimaryButton, TablePagination } from "../../components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export default function WaitingUsers() {

  const navigate = useNavigate();
  const tableHeaders = [
    { index: 0, label: "", },
    { index: 1, label: "Name", sort: "name" },
    { index: 2, label: "Email", sort: "email" },
    { index: 3, label: "Action" },
  ];

  const { getWaitingUsers, deleteWaitingUser } = useContext(AdminContext);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sort, setSort] = useState("DESC");

  useEffect(() => {
    const params = `page=${page}&sortBy=${sortBy}&sort=${sort}`;
    getInfo(params);
  }, []);

  const getInfo = async (params) => {
    setLoading(true);
    const result = await getWaitingUsers(params);
    setItems(result?.data?.items ?? []);
    setPagination(result?.data?.pagination);
    setLoading(false);
  };

  const deleteItem = async () => {
    await deleteWaitingUser(selectedItem?.id);      
    const filterItems = items?.filter((item) => item.id !== selectedItem?.id);
    setItems(filterItems);
    setSelectedItem(null);
    setOpenModal(false);
    toast.success("Successfully deleted");
  };

  const sortHandle = (sortByItem) => {
    setSortBy(sortByItem);
    const sortItem = sort === "ASC" ? "DESC" : "ASC"
    setSort(sortItem);
    const params = `page=${page}&sortBy=${sortByItem}&sort=${sortItem}`;
    getInfo(params);
  };

  const renderSkeleton = () => {
    return (
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((item, index) => {
                return <TableCell key={index}>{item.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(10)].map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    />
                  </TableCell>
                  <TableCell><Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    /></TableCell>
                  <TableCell><Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    /></TableCell>
                  <TableCell>
                  <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const renderView = () => {
    return (
      <Box>          
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((item, index) => {
                if (item.sort) {
                  return <TableCell key={index}>
                    <TableSortLabel
                      active={sortBy === item.sort}
                      direction={sort.toLowerCase()}
                      onClick={() => {
                        sortHandle(item.sort);
                      }}
                    >
                      {item.label}
                      <Box
                        component={"span"}
                        sx={visuallyHidden}
                      >
                        {sort === "DESC" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    </TableSortLabel>
                  </TableCell>;
                } else {
                  return <TableCell key={index}>{item.label}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell>
                    {index +
                      1 +
                      (pagination?.currentPage - 1) * pagination?.limit}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <PrimaryButton
                      label={"Delete"}
                      sx={{
                        height: 30,
                        fontSize: 14,
                      }}
                      handleClick={() => {
                        setSelectedItem(item);
                        setOpenModal(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {pagination && <Box
          sx={{
            mt: 2,
            paddingX: 2,
          }}
        >
          <TablePagination
            totalPage={pagination?.lastPage}
            totalCount={pagination?.totalCount}
            page={Number(pagination?.currentPage)}
            onChangePage={(handlePage) => {
              setPage(handlePage);
              const params = `page=${handlePage}&sortBy=${sortBy}&sort=${sort}`;
              getInfo(params);
            }}
            itemName={"Items"}
          />
        </Box>}
      </Box>
    );
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Stack
          sx={{mb: 0}}
          direction={"row"}
          justifyContent={"end"}
        >
          <PrimaryButton
            label={"+ New User"}
            sx={{
              width: 150,
              fontSize: 14,
              height: 40,
            }}
            handleClick={() => {
              navigate("/admin/waiting-users/create");
            }}
          />
        </Stack>
        { loading ? renderSkeleton() : renderView()}
      </Box>
      <DeleteWaitingUserConfirmModal
        isOpenModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        confirm={() => {
          deleteItem();
        }}
      />
    </AdminLayout>
  );
}
