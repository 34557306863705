import { TabContext, TabList } from "@mui/lab";
import { Box, Container, Stack, Tab, Typography } from "@mui/material";
import { useState } from "react";
import config from "../../../config/config";
import writeIcon from "../../../assets/images/waiting-list/write.svg";
import readIcon from "../../../assets/images/waiting-list/read.svg";
import shareIcon from "../../../assets/images/waiting-list/share.svg";

export default function HowStoryWorks(props) {

  const {isPadding = false} = props;

  const images = [
    {
      image: writeIcon,
      title: "Write",
      description: [
        "• Write stories of any length",
        "• Embed photos and videos to your stories",
        "• Write with co-authors and collaborators",
      ],
    },
    {
      image: readIcon,
      title: "Read",
      description: [
        "• Search for stories based on tags like time period or historical events",
        "• Learn about the lives of real people who’ve submitted to our growing library",
      ],
    },
    {
      image: shareIcon,
      title: "Share",
      description: [
        "• Choose who you share with public or private it’s up to you",
        "• Add tags so others can easily find your story",
        "• Open your story to the world and let new readers find you",
      ],
    },
  ];

  return (
    <Box
      sx={{
        paddingY: 6,
      }}
    >
      <Container maxWidth={"lg"}
        
      >
        <Box
        sx={{
          paddingX: {
            md: isPadding ? 6 : 0,
            sm: 0,
          },
        }}
        >
        <Typography
          textAlign={"center"}
          sx={{
            color: config.colors.primaryTextColor,
            fontSize: {
              sm: "53px",
              xs: "33px"
            },
            fontFamily: "Libre Franklin",
            fontWeight: 800,
          }}
        >
          How Storymoir Works
        </Typography>
        {images.map((item, index) => {
          return (
            <Stack
              key={index}
              direction={{
                md: "row",
                xs: "column",
              }}
              alignItems={"center"}
              spacing={0}
              sx={{
                backgroundColor: "rgba(171, 172, 184, 0.08)",
                marginY: 3,
              }}
            >
              <Stack
                direction={"column"}
                alignItems={"center"}
                sx={{
                  backgroundColor: "rgba(217, 217, 217, 0.20)",
                  padding: 3,
                  paddingX: 6,
                  width: {
                    xs: "100%",
                    md: "auto",
                  },
                }}
              >
                <img src={item.image} width={100} height={122}/>
                <Typography
                  sx={{
                    fontSize: {
                      sm: "30px",
                      xs: "30px",
                    },
                    lineHeight: "42px",
                    fontWeight: 700,
                  }}
                >{item.title}</Typography>
              </Stack>
              <Stack
                direction={"column"}
                alignItems={"start"}
                sx={{
                  paddingX: 5,
                  paddingY: {
                    md: 0,
                    xs: 2,
                  }
                }}
                spacing={1}
              >
                {item.description?.map((text, index) => {
                  return <Typography key={index} sx={{
                    fontSize: {
                      sm: "25px",
                      xs: "18px"
                    },
                    lineHeight: "30px",
                    fontWeight: 400,
                  }}>{text}</Typography>
                })}
              </Stack>
            </Stack>
          );
        })}
        </Box>
      </Container>
    </Box>
  );
}
