import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext, AxiosContext } from '../../../context';
import '../../styles/auth.scss';
import { Footer } from '../../components';
// import routes from '../../../config/routes';

import imgBookPad from '../../../assets/images/bookpad.svg';
  
  export default function LoginPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const utm_source = searchParams.get("utm_source");
    const utm_medium = searchParams.get("utm_medium");
    const utm_content = searchParams.get("utm_content");
    const email = searchParams.get("email");
    const { setLoading } = useContext(AxiosContext);
    const { login } = useContext(AuthContext);
    const [pwdShow, setPwdShow] = useState(false);
    const [formData, setFormData] = useState({
      email: '',
      password: ''
    });

    useEffect(() => {
      setFormData({...formData, email: email});
    }, [email]);

    const handleSubmit = async() => {
      setLoading(true);
      const user = await login(formData);
      setLoading(false);
      if (user.token) {
        // if(redirectPath && routes.filter((route) => route.path === redirectPath ).length > 0) {
        if(redirectPath) {
          let _searchParams = '';
          if(utm_source) {
            _searchParams += `&utm_source=${utm_source}`;
          } 
          if(utm_medium) {
            _searchParams += `&utm_medium=${utm_medium}`;
          } 
          if(utm_content) {
            _searchParams += `&utm_content=${utm_content}`;
          } 
          _searchParams = _searchParams.replace('&', '');
          
          navigate(`${redirectPath}${_searchParams ? ('?' + _searchParams) : ''}`);
        } else {
          navigate('/');
        }
      } else {
        toast(user);
      }
    };

    const handleEnterKeyPress = (e) => {
      if(e.keyCode === 13) {
        handleSubmit();
      }
    }
  
    return (
      <div className="page auth login">
        <div className="container">
          <div className="auth-container">
            <div className="auth-banner">
              <div className="banner-img mb-5">
                <img src={imgBookPad} className="w-100" alt="" />
              </div>
            </div>
            <div className="auth-form mx-auto">
              <div className="auth-card">
                <h1 className="headline">Log in to Storymoir</h1>
                <p className="auth-subline"></p>
                <form className="form-wrapper">
                  {/* <div className="form-group">
                    <label>Username</label>
                    <div className="form-control-wrapper">
                      <input type="text" className="form-control" placeholder="MG-tester" />
                      <span className="form-check-icon"></span>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-control-wrapper">
                      <input type="email" className="form-control" placeholder="iam@mikegrossman.com" 
                        name="email" 
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})} 
                      />
                      {/* <span className="form-check-icon"></span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="form-control-wrapper">
                      <input type={pwdShow ? "text" : "password"} className="form-control" placeholder="••••••••••" 
                        name="password" 
                        value={formData.password}
                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                        onKeyUp={(e) => handleEnterKeyPress(e)}
                      />
                      <span className="eye-icon cursor-pointer" 
                        onMouseDown={() => setPwdShow(true)}
                        onMouseUp={() => setPwdShow(false)}
                      ></span>
                      {/* <span className="v-dotted-line"></span> */}
                      {/* <span className="form-check-icon"></span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-continue mt-4"
                      type="button"
                      onClick={() => handleSubmit()}
                    >Login</button>
                  </div>
                </form>
                {/* <div className="auth-subline">
                  Don't have an account? <Link className="auth-link fw-bold" to={`/auth/register${redirectPath ? '?redirect=' + redirectPath : ''}`}>Sign Up</Link>
                </div> */}
                <div className="terms-line mb-4">
                  By continuing, you agree to Storymoir’s <Link className="auth-link">Terms of Service</Link> and <Link className="auth-link" to={`/privacy`}>Privacy Policy</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }