import { CookiesProvider } from 'react-cookie';

import { AxiosContextProvider } from './axios_context';
import { AuthContextProvider } from './auth_context';
import { ServiceContextProvider } from './service_context';
import { StoryContextProvider } from './story_context';
import { UserContextProvider } from './user_context';
import { CategoryContextProvider } from './category_context';
import { SearchContextProvider } from './search_context';
import { CommentContextProvider } from './comment_context';
import { GuestContextProvider } from './guest_context';
import { SurveyContextProvider } from './survey_context';
import { ShareContextProvider } from './share_context';
import { DelegateContextProvider } from './delegate_context';
import { AdminContextProvider } from './admin_context';

const ContextProvider = (props) => {
    return (

        <CookiesProvider>
            <AuthContextProvider>
                <GuestContextProvider>
                    <AxiosContextProvider>
                        <DelegateContextProvider>
                            <ShareContextProvider>
                                <SurveyContextProvider>
                                    <SearchContextProvider>
                                        <CategoryContextProvider>
                                            <UserContextProvider>
                                                <ServiceContextProvider>
                                                    <StoryContextProvider>
                                                        <CommentContextProvider>
                                                            <AdminContextProvider>
                                                            {props.children}
                                                            </AdminContextProvider>
                                                        </CommentContextProvider>                                            
                                                    </StoryContextProvider>
                                                </ServiceContextProvider>
                                            </UserContextProvider>
                                        </CategoryContextProvider>
                                    </SearchContextProvider>
                                </SurveyContextProvider>
                            </ShareContextProvider>
                        </DelegateContextProvider>
                    </AxiosContextProvider>
                </GuestContextProvider>
            </AuthContextProvider>
        </CookiesProvider>
    );
}

export default ContextProvider;