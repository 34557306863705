import { Box, InputLabel, Typography, Stack } from "@mui/material";
import config from "../../../config/config";

export default function PrimaryTextArea(props) {
  const {
    label,
    subLabel,
    placeholder,
    name,
    handleChange,
    value,
    required = false,
    sx,
    error = false,
    rows= 5,
    maxLength = 288,
  } = props;

  return (
    <Box>
      <Stack direction={"column"}>
        {label && (
          <InputLabel
            sx={{
              color: config.colors.primaryTextColor,
              fontFamily: "Libre Franklin",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
              marginBottom: "10px",
            }}
          >
            {label}
            {subLabel && <span style={{ fontWeight: 400, marginLeft: 4}}>{subLabel}</span>}
            {required && <span style={{ color: "red" }}>*</span>}
          </InputLabel>
        )}
        <textarea
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          required={required}  
          rows={rows}        
          maxLength={maxLength}
          style={{
            border: "none",
            display: "flex",
            alignItems: "center",   
            padding: "20px 35px",        
            boxShadow: "inset 0px 2px 6px #d2d2d2",
            transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",
            outline: "none",
            borderRadius: "0",
            borderColor: "none",   
            background: "#fff!important",
            ...sx,
          }}
        />
        {error && !value && (
          <Typography variant="caption" color={"red"} sx={{ mt: 0.5 }}>
            Required the field
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
