import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {PrimaryButton, DefaultButton} from "../../components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function DeleteWaitingUserConfirmModal(props) {
  const { isOpenModal = false, closeModal, confirm } = props;

  return (
    <Modal
      open={isOpenModal}
      onClose={closeModal}
      aria-labelledby="Delete Story Confirm Modal"
    >
      <Box sx={style}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"end"}>
          <HighlightOffIcon
            onClick={closeModal}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500 }}
            textAlign={"center"}
          >
            Are You Sure You Want To Delete This Waiting User?
          </Typography>
          <Stack
            direction={"row"}
            mt={3}
            spacing={2}
            alignItems={"center"}
            justifyContent="center"
          >
            <PrimaryButton
              label="Yes, Please Delete"
              disabled={false}
              handleClick={() => confirm()}
              sx={{
                height: 40,
                width: 210,
              }}
            />
            <DefaultButton
              label="Cancel"
              handleClick={closeModal}
              sx={{
                height: 40,
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
