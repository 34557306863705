import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import logo from '../../../assets/images/STORYMOIR-LOGO.svg';
import avatar from '../../../assets/images/avatar.svg';

import { AuthContext, SearchContext, GuestContext, UserContext } from '../../../context';
import ReadingProgressBar from './reading_progress_bar';
import { Box, Stack } from '@mui/material';

export default function MainHeader({ showReadingProgress = false }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout } = useContext(AuthContext);
    const { searchText } = useContext(SearchContext);
    const { setPrevRoute, isScrolling, setIsScrolling } = useContext(GuestContext);
    const { getTopAuthors, topAuthors } = useContext(UserContext);

    const btnRef = useRef(null);
    const [headerClass, setHeaderClass] = useState('');
    const [queryText, setQueryText] = useState('');

    useEffect(() => {
        getTopAuthors();
        window.addEventListener('scroll', handleScrollEvent);
        return () => window.removeEventListener('scroll', handleScrollEvent);
    }, []);

    useEffect(() => {
        checkQueryText();
    }, [searchText]);

    const checkQueryText = () => {
        if(searchText && location.pathname.includes('search')) {
            setQueryText(searchText);
        }
    }

    const handleScrollEvent = (e) => {
        if(e.target.documentElement.scrollTop > 0) {
            setHeaderClass('scrolled');
            setIsScrolling(true);
        } else {
            setHeaderClass('');
            setIsScrolling(false);
        }
    }

    const handleEnterKey = (e) => {
        if(e.keyCode === 13) {
            // navigate(`/search/${queryText}`);
            if (queryText) {
                navigate(`/stories?query=${queryText}`);
            } else {
                navigate(`/stories`);
            }
            
        }
    }

    const handleClickMenu = (route) => {
        setPrevRoute(location);
        navigate(route);
    }

    const handleLogout = () => {
        logout().then(res => {
            if(res) {
                
            }
        });
    }

    return (
        <Navbar className={`${headerClass} d-flex flex-column`} expand="lg" variant="light" bg="white">
            <Container>
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="logo" alt="" />
                </Link>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                >
                <Navbar.Collapse>
                    <Nav>
                        <button className="btn-close-nav"
                            onClick={() => btnRef.current.click()}
                        ></button>
                        {
                            user ? <Nav.Link href={`/user/${user.id}`} className="d-block d-sm-none">Profile</Nav.Link> : <></>
                        }
                        <Nav.Link className="nav-link" onClick={() => handleClickMenu(`/mystories/new`)}>Write</Nav.Link>
                        <Nav.Link className="nav-link" onClick={() => handleClickMenu(`/stories`)}>Browse</Nav.Link>
                        <div className="hover-dropdown">
                            {/* <Link className="nav-link" to="/stories">Browse</Link> */}
                            <div className="dropdown-menu">
                                <h5>Story Type</h5>
                                <Link className="dropdown-item" to="/stories/types/shortstory">Short Stories</Link>
                                <Link className="dropdown-item" to="/stories/types/memoir">Memoirs</Link>
                                <Link className="dropdown-item" to="/stories/types/biography">Biographies</Link>
                                <NavDropdown.Divider />
                                <h5>Popular Authors</h5>
                                {
                                    topAuthors && topAuthors?.map((item, index) =>
                                        index < 3 
                                        ? <Link className="dropdown-item" to={`/user/${item?.id}`} key={index}>{item?.fullName ? item?.fullName : item?.username}</Link>
                                        : null
                                    )
                                }
                                <Link className="dropdown-item" to="/authors/top">See all top authors</Link>
                                <NavDropdown.Divider />
                                <h5>Stories About</h5>
                                <Link className="dropdown-item" to="/stories/categories/love">Love</Link>
                                <Link className="dropdown-item" to="/stories/categories/grief">Grief</Link>
                                <Link className="dropdown-item" to="/stories/categories/success">Success</Link>
                                <Link className="dropdown-item" to="/stories/categories/family-history">Family History</Link>
                            </div>
                        </div>
                        {/* <Nav.Link href="#guide">Writing Guide</Nav.Link> */}
                        {user ? <Nav.Link href="/library" className="d-block d-sm-none">Library</Nav.Link> : <></>}
                        {/* {user ? <Nav.Link href="/delegates" className="d-block d-sm-none">Delegate Acc</Nav.Link> : <></>} */}
                        {user ? <Nav.Link href="#/settings" className="d-block d-sm-none">Settings</Nav.Link> : <></>}
                        {user ? <Nav.Link className="d-block d-sm-none" onClick={() => handleLogout()}>Log off</Nav.Link> : <></>}
                        <div className="search-form">
                            <span className="search-icon"></span>
                            <input className="search-input" placeholder="Search" 
                                value={queryText}
                                onChange={(e) => setQueryText(e.target.value)}
                                onKeyUp={(e) => handleEnterKey(e)}
                            />
                        </div>
                    </Nav>
                </Navbar.Collapse>
                <div className="search-form" style={{minWidth: 400}}>
                    <div className="search-prefix">
                        <span className="search-icon"></span>
                        {/* <span className="search-label">Search by</span>
                        <span className="search-divider"></span> */}
                    </div>
                    <input className="search-input w-100" placeholder="Search by author's name, story title, or story tags" 
                        value={queryText}
                        onChange={(e) => setQueryText(e.target.value)}
                        onKeyUp={(e) => handleEnterKey(e)}
                    />
                </div>
                {
                    !user 
                        ? <Button className="btn-login" variant="primary" onClick={() => navigate(`/auth/register`)}>Log In</Button> 
                        : <Dropdown className="profile-dropdown d-none d-sm-block">
                            <Dropdown.Toggle variant="default">
                                <div className="avatar">
                                    <img src={user.imageUrl ? user.imageUrl : avatar} className="object-fit-cover rounded-circle" alt={user.username} />
                                </div>
                            </Dropdown.Toggle>
                    
                            <Dropdown.Menu>
                                <Link className="dropdown-item" to={`/user/${user.id}`}>Profile</Link>
                                <Dropdown.Divider />
                                <Nav.Link className="dropdown-item" onClick={() => handleClickMenu(`/mystories/new`)}>Write a Story</Nav.Link>
                                <Dropdown.Divider />
                                <Link className="dropdown-item" to="/library">Library</Link>
                                <Dropdown.Divider />
                                {/* <Link className="dropdown-item" to="/delegates">Delegate Acc</Link>
                                <Dropdown.Divider /> */}
                                {/* <Link className="dropdown-item" to="#/languages">Languages</Link>
                                <Dropdown.Divider /> */}
                                <Link className="dropdown-item" to="/settings">Settings</Link>
                                <Dropdown.Divider />
                                <Link className="dropdown-item" onClick={() => handleLogout()}>Log off</Link>
                            </Dropdown.Menu>
                        </Dropdown>

                }
                <Navbar.Toggle aria-controls="basic-navbar-nav" ref={btnRef} />
                </Stack>
            </Container>
            {
                showReadingProgress && <ReadingProgressBar />
            }
        </Navbar>
    );
};