import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import '../../styles/start.scss';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Footer } from '../../components';

import imgLogo from '../../../assets/images/STORYMOIR-LOGO.svg';
import { AuthContext, SurveyContext, AxiosContext } from '../../../context';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 66,
  height: 33,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 27,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(29px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#DB805E' : '#DB805E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 27,
    height: 27,
    borderRadius: 27 / 2,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 33 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

  
  export default function WriterSurveyPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const opt = searchParams.get("opt");
    const redirectPath = searchParams.get("redirect");
    const { writerSurveys, getWriterSurveys, createOrUpdateSurveyResults } = useContext(SurveyContext);
    const { setLoading } = useContext(AxiosContext);
    const { user } = useContext(AuthContext);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
      getWriterSurveys().then(res => {
        if(res.status) {
          const data = [];
          for (let i = 0; i < res.data.length; i++) {
            data.push({
              ...res.data[i],
              user_id: user?.id,
              answer: '',
              is_show: true
            });
          }
          console.log('==== ', data);
          setFormData(data);
        }
      });
    }, [user]);

    const handleFormChange = (index, column, value, inputType) => {
      console.log('---1---', index, column, value, inputType);
      let _value = value;
      if(inputType === 'checkbox') {
          _value = formData[index][column];
          console.log('===', _value);
          if (_value.includes(value)) {
              _value = _value.replace(value, '').split(',').filter(ele => ele !== '').join(',');
          } else {
              _value += _value ? (',' + value) : value;
          }
      }
      const newFD = formData.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            [column]: _value
          }
        }
        return obj
      });
      console.log('---2---', newFD);
      setFormData(newFD);
  }

    const handleSubmit = () => {
      setLoading(true);
      createOrUpdateSurveyResults({surveys: formData}).then((res) => {
        setLoading(false);
        if(res.status) {
          navigate(`${opt === 'wr' ? `/start/readersurvey${redirectPath ? '?redirect=' + redirectPath : ''}` : `/start/success${redirectPath ? '?redirect=' + redirectPath : ''}`}`)
        }
      });
    }
  
    return (
      <div className="start">
        <div className="container py-60px">
          <div className="logo mx-auto mb-4">
            <Link to={`/`}>
              <img src={imgLogo} className="w-100" alt="" />
            </Link>
          </div>
          <div className="thanks-msg mx-auto">
            Thanks.
            <br />
            Before we get to writing, tell us a little bit more about yourself.
          </div>
          <p className="survey-subline mx-auto">
            All this info is optional, but it will help us show your stories to the people who're looking for them.
          </p>
          <div className="survey-form mx-auto mt-5">
            {
              formData && formData.map((item, index) => 
                {
                  if(item.type === 'date' || item.type === 'text') {
                    return (
                      <div className="row" key={index}>
                        <div className="col-12 col-sm-12 col-md-8">
                          <div className="inline-group-sm mx-auto">
                            <div className="row w-100">
                              <div className="col-4 d-flex align-items-center justify-content-end">
                                <label className="sub-label">
                                  {item.question}
                                </label>
                              </div>
                              <div className="col-8">
                                <input type={item.type} className="form-control" name={item.id} placeholder={item.question} 
                                  value={item.answer} 
                                  onChange={(e) => handleFormChange(index, 'answer', e.target.value, item.type)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                          <div className="toggle-show-switch mx-auto">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} 
                                checked={item.is_show} 
                                onChange={(e) => handleFormChange(index, 'is_show', !item.is_show, item.type)}
                              />
                              <Typography className='switch-label'>Show info</Typography>
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )
                  } else if(item.type === 'checkbox') {
                    return (
                      <div className="row" key={index}>
                        <div className="col-12 col-sm-12 col-md-8">
                          <div className="form-group">
                            <label className="form-label">{item.question}</label>
                            {
                              item.ansOptions && item.ansOptions.split(',').map((option, optIndex) =>
                                <div className="inline-group" key={optIndex}>
                                  <input type="checkbox" className="form-checkbox" 
                                    checked={item.answer.includes(option) ? true : false} 
                                    value={item.answer}
                                    onChange={(e) => handleFormChange(index, 'answer', option, item.type)}
                                  />
                                  {option}
                                </div>
                              )
                            }
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                          <div className="form-group">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} 
                                checked={item.is_show} 
                                onChange={(e) => handleFormChange(index, 'is_show', !item.is_show, item.type)}
                              />
                              <Typography className='switch-label'>Show info</Typography>
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )
                  } else if(item.type === 'textarea') {
                    return (
                      <div className="form-group" key={index}>
                        <label className="form-label">{item.question}</label>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-8">
                            <textarea className="form-textarea mb-3" rows={7}
                              value={item.answer} 
                              onChange={(e) => handleFormChange(index, 'answer', e.target.value, item.type)}
                            ></textarea>
                          </div>
                          <div className="col-12 col-sm-12 col-md-4">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} 
                                checked={item.is_show} 
                                onChange={(e) => handleFormChange(index, 'is_show', !item.is_show, item.type)}
                              />
                              <Typography className='switch-label'>Show info</Typography>
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
              )
            }
            
            <div className="row">
              <div className="col-12">
                <button className="btn btn-primary btn-submit"
                  onClick={() => handleSubmit()}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}