import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import imgFavIcon from '../../../assets/images/favicon.svg';
import socialTwitter from '../../../assets/images/icons/twitter.svg';
import socialFacebook from '../../../assets/images/icons/facebook.svg';
import socialLinkedin from '../../../assets/images/icons/linkedin.svg';

export default function Footer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <footer className="footer">
            <div className="torn-paper"></div>
            <div className="container">
                <div className="sub-container py-0">
                    <div className="row">
                        <div className="d-none d-md-flex col-md-6">
                            <div className="d-flex align-items-center">
                                <img src={imgFavIcon} className="footer-logo" alt="" />
                                <ul className="footer-navs">
                                    <li>
                                        <Link to="">Press</Link>
                                    </li>
                                    <li>
                                        <Link to="">Work with Us</Link>
                                    </li>
                                    <li>
                                        <Link to="">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="share-info">
                                <ul className="social-navs mt-2">
                                    <li>
                                        <Link to="">
                                            <img src={socialTwitter} alt="" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <img src={socialFacebook} alt="" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <img src={socialLinkedin} alt="" />
                                        </Link>
                                    </li>
                                </ul>
                                <div className="phone">800-123-4567</div>
                                <div className="address">Address to go here</div>
                                <div className="address">NY, NY 2000</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="bottom-navs">
                                <span>©2023 Storymoir </span>
                                <ul>
                                    <li>
                                        <Link to="">Terms</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy</Link>
                                    </li>
                                    <li>
                                        <Link to="">Accessibility</Link>
                                    </li>
                                    <li>
                                        <Link to="">Help</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
