import {  useNavigate } from "react-router-dom";
import { Footer, MainHeader } from '../components';
import '../styles/search.scss';

import { useEffect, useState, useContext } from "react";
import { AuthContext, AxiosContext, UserContext } from '../../context';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

export default function SettingsPage() {
    const navigate = useNavigate();
    const { setLoading } = useContext(AxiosContext);
    const { getMyInfo, token, user } = useContext(AuthContext);
    const { changeUsername, changePassword, changeEmail, updateUser } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState('account'); // account, notifications
    const [showUsernameChangeModal, setShowUsernameChangeModal] = useState(false);
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
    const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
    const [userData, setUserData] = useState({
        userId: '',
        dob: ''
    });
    const [usernameData, setUsernameData] = useState({
        userId: '',
        newUsername: '',
        confirmPassword: ''
    });
    const [passwordData, setPasswordData] = useState({
        userId: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [emailData, setEmailData] = useState({
        userId: '',
        newEmail: '',
        confirmNewEmail: '',
        confirmPassword: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        initUserInfo();
    }, [token]);

    const initUserInfo = () => {
        getMyInfo().then(res => {
            if(res.status) {
                setUserData({
                    ...userData, 
                    id: res.data.id, 
                    dob: res.data.dob 
                });
            }
        });
    }

    const handelClickTab = (e) => {
        setActiveTab(e);
    }

    const onChangeUsername = () => {
        changeUsername({...usernameData, userId: user.id}).then(res => {
            if(res.status) {
                toast("Successfully updated");
                initUserInfo();
                setShowUsernameChangeModal(false);
            } else {
                toast(res.data);
            }
        });
    }

    const onChangePassword = () => {
        changePassword({...passwordData, userId: user.id}).then(res => {
            if(res.status) {
                toast("Successfully updated");
                initUserInfo();
                setShowPasswordChangeModal(false);
            } else {
                toast(res.data);
            }
        });
    }

    const onChangeEmail = () => {
        changeEmail({...emailData, userId: user.id}).then(res => {
            if(res.status) {
                toast("Successfully updated");
                initUserInfo();
                setShowEmailChangeModal(false);
            } else {
                toast(res.data);
            }
        });
    }

    const onsubmit = () => {
        updateUser(userData).then(res => {
            if(res.status) {
                toast("Successfully updated");
                initUserInfo();
            } else {
                toast(res.data);
            }
        });
    }

    return (
        <div className="page stories bg-white">
            <MainHeader />
            <section className="section-header">
                <div className="container">
                    <div className="sub-container">
                        <div className="row m-0 w-100">
                            <div className="col-12 col-sm-6 px-0 d-flex align-items-center">
                                <h1 className="headline m-0">Settings</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-stories">
                <div className="container">
                    <div className="sub-container">
                        <ul className="story-tabs mb-0 nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <button type="button" className={`nav-link ${activeTab === 'account' ? 'active' : ''}`}
                                    onClick={() => handelClickTab('account')}
                                >Account</button>
                            </li>
                            {/* <li className="nav-item">
                                <button type="button" className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`}
                                    onClick={() => handelClickTab('notifications')}
                                >Notifications</button>
                            </li> */}
                        </ul>
                        <div className="tab-content pb-5">
                            <div role="tabpanel" className={`fade tab-pane ${activeTab === 'account' ? 'active show' : ''}`}>
                                <h6 className="mt-3">Change your account information and privacy settings</h6>
                                <div className="user-list pb-0">
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-end">Username</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-start">
                                                { user?.username }
                                                <a className="btn-open-modal ms-2" onClick={() => setShowUsernameChangeModal(true)}>change</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-end">Password</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-start">
                                                { "********" }
                                                <a className="btn-open-modal ms-2" onClick={() => setShowPasswordChangeModal(true)}>change</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-end">Email</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-start">
                                                { user?.email }
                                                <a className="btn-open-modal ms-2" onClick={() => setShowEmailChangeModal(true)}>change</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <label className="w-100 h-100 d-flex align-items-center justify-content-end">Date of Birth</label>
                                        </div>
                                        <div className="col-6">
                                            <input type="date" className="form-control" style={{'maxWidth': '220px'}} 
                                                value={userData.dob} 
                                                onChange={(e) => setUserData({...userData, dob: e.target.value})} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button className="btn btn-primary mt-2"
                                                onClick={() => onsubmit()}
                                            >Submit</button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/* <button className="">Close Account</button> */}
                            </div>
                            <div role="tabpanel" className={`fade tab-pane ${activeTab === 'notifications' ? 'active show' : ''}`}>
                                <div className="story-list py-5">
                                    Notifications Settings
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {/* UserName Change Modal */}
            <Modal
                show={showUsernameChangeModal}
                onHide={() => setShowUsernameChangeModal(false)}
                backdrop="static"
                keyboard={false}
                className="userinfo-change-modal"
            >
                <Modal.Header className="border-0">
                    <Modal.Title>Change username</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Your username is important, Your user profile and widget links will change and the old ones will no longer work.
                    </p>
                    {/* <p>
                        Your username is important, here are a few things you need to know before you change it:
                    </p>
                    <ol>
                        <li>It takes up to 24 hours for all changes to take effect. Your profile and stories may be inaccessible during this time.</li>
                        <li>Your user profile and widget links will change and the old ones will no longer work.</li>
                        <li>You will need to re-login using your new username.</li>
                    </ol> */}
                    <div className="form-group">
                        <label className="mt-3">New Username</label>
                        <input type="text" className="form-control mt-1" placeholder="New Username" 
                            value={usernameData.newUsername} 
                            onChange={(e) => setUsernameData({...usernameData, newUsername: e.target.value})} 
                        />
                    </div>
                    <div className="form-group">
                        <label className="mt-3">Confirm Password</label>
                        <input type="password" className="form-control mt-1" placeholder="Confirm Password" 
                            value={usernameData.confirmPassword} 
                            onChange={(e) => setUsernameData({...usernameData, confirmPassword: e.target.value})} 
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" 
                        className="btn btn-primary ms-auto"
                        onClick={() => onChangeUsername()}
                    >
                        Save
                    </Button>
                    <Button variant="primary" 
                        className="btn btn-primary me-auto"
                        onClick={() => setShowUsernameChangeModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Password Change Modal */}
            <Modal
                show={showPasswordChangeModal}
                onHide={() => setShowPasswordChangeModal(false)}
                backdrop="static"
                keyboard={false}
                className="userinfo-change-modal"
            >
                <Modal.Header className="border-0">
                    <Modal.Title>Change password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-3">
                        <label className="mt-3">Current Password</label>
                        <input type="password" className="form-control mt-1" placeholder="Current Password" 
                            value={passwordData.currentPassword} 
                            onChange={(e) => setPasswordData({...passwordData, currentPassword: e.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label className="mt-3">New Password</label>
                        <input type="password" className="form-control mt-1" placeholder="New Password" 
                            value={passwordData.newPassword} 
                            onChange={(e) => setPasswordData({...passwordData, newPassword: e.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label className="mt-3">Confirm Password</label>
                        <input type="password" className="form-control mt-1" placeholder="Confirm Password" 
                            value={passwordData.confirmPassword} 
                            onChange={(e) => setPasswordData({...passwordData, confirmPassword: e.target.value})}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" 
                        className="btn btn-primary ms-auto"
                        onClick={() => onChangePassword()(false)}
                    >
                        Save
                    </Button>
                    <Button variant="primary" 
                        className="btn btn-primary me-auto"
                        onClick={() => setShowPasswordChangeModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Email Change Modal */}
            <Modal
                show={showEmailChangeModal}
                onHide={() => setShowEmailChangeModal(false)}
                backdrop="static"
                keyboard={false}
                className="userinfo-change-modal"
            >
                <Modal.Header className="border-0">
                    <Modal.Title>Change email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="mt-3">New Email</label>
                        <input type="text" className="form-control mt-1" placeholder="New Email" 
                            value={emailData.newEmail} 
                            onChange={(e) => setEmailData({...emailData, newEmail: e.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label className="mt-3">Confirm New Email</label>
                        <input type="text" className="form-control mt-1" placeholder="Confirm New Email" 
                            value={emailData.confirmNewEmail} 
                            onChange={(e) => setEmailData({...emailData, confirmNewEmail: e.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label className="mt-3">Confirm Password</label>
                        <input type="password" className="form-control mt-1" placeholder="Confirm Password" 
                            value={emailData.confirmPassword} 
                            onChange={(e) => setEmailData({...emailData, confirmPassword: e.target.value})}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" 
                        className="btn btn-primary ms-auto"
                        onClick={() => onChangeEmail()(false)}
                    >
                        Save
                    </Button>
                    <Button variant="primary" 
                        className="btn btn-primary me-auto"
                        onClick={() => setShowEmailChangeModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}