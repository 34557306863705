import React, { useEffect, useContext, useState } from "react";
import {
  Footer,
  MainHeader,
  StoryListItem,
  StoryFilterByOptions,
  TitleList,
  StoryListItemSkeleton,
} from "../../components";
import "../../styles/category_stories.scss";
import { AuthContext, SearchContext } from "../../../context";
import { Box, Container, Stack, Typography } from "@mui/material";
import config from "../../../config/config";
import { useSearchParams } from "react-router-dom";

export default function StoriesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchStories, filterStories } = useContext(SearchContext);
  const { user } = useContext(AuthContext);
  const tabs = [
    {
      label: "All",
      value: "1",
      index: "createdAt"
    },
    {
      label: "Authors",
      value: "2",
      index: "fullName"
    },
    {
      label: "Titles",
      value: "3",
      index: "title",
    },
    {
      label: "Tags",
      value: "4",
      index: "tags"
    },
  ];
  const [filterParams, setFilterParams] = useState({
    status: "PUBLISH",
    storyReadingTime: [],
    storyType: [],
    sortBy: [],
    tab: 1,
  });
  const [showLoading, setShowLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    setSearchQuery(searchParams.get("query"));
  }, [searchParams]);

  useEffect(() => {
    setShowLoading(true);
    searchStories(searchQuery, filterParams).then((res) => {
      setShowLoading(false);
    });
  }, [filterParams, searchQuery]);

  const handleChangeFilterParam = (column, e) => {
    console.log(column);
    let param = filterParams[column];
    console.log(param);
    if (e.target.checked) {
      param.push(e.target.value);
    } else {
      param.splice(param.indexOf(e.target.value), 1);
    }
    setFilterParams({
      ...filterParams,
      [column]: param,
    });
  };

  const handleTitleChange = (v) => {
    setFilterParams({
      ...filterParams,
      tab: v,
    });
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      <MainHeader />
      <Box
        sx={{
          pb: "60px",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Lora",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "46px",
                  lineHeight: "55px",
                  color: config.colors.primaryTextColor,
                }}
              >
                Browse A Story
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Libre Franklin",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "30px",
                  lineHeight: "42px",
                  color: config.colors.primaryTextColor,
                  mt: 2,
                }}
              >
                Welcome back{user ? ", " : ""}
                {user?.firstName ? user?.firstName : user?.username}!
              </Typography>
            </Box>
            <StoryFilterByOptions
              filterParams={filterParams}
              handleChangeFilterParam={handleChangeFilterParam}
            />
          </Stack>
          <TitleList handleChange={(v) => handleTitleChange(v)} tabs={tabs} />
          {showLoading
            ? [...Array(3)].map((item, index) => {
                return <StoryListItemSkeleton key={index} item={item} />;
              })
            : filterStories &&
              filterStories.map((item, index) => (
                <StoryListItem item={item} key={index} />
              ))}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
