import { Link, useNavigate } from "react-router-dom";
import { Footer, MainHeader, DelegatedAccessItem } from '../../components';
import { useEffect, useState, useContext } from "react";
import { DelegateContext, AxiosContext } from '../../../context';

export default function DelegatePage() {
    const navigate = useNavigate();
    const {
        delegatedAccesses,
        getDelegatedAccesses
    } = useContext(DelegateContext);
    const { setLoading } = useContext(AxiosContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        getDelegatedAccesses();
    }, []);

    return (
        <div className="page stories bg-white">
            <MainHeader />
            <section className="section-header">
                <div className="container">
                    <div className="sub-container">
                        <div className="row m-0 w-100">
                            <div className="col-12 col-sm-6 px-0 d-flex align-items-center">
                                <h1 className="headline m-0">Delegate Access</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-stories">
                <div className="container">
                    <div className="sub-container">
                        <hr />
                        <div className="row user-list">
                            {
                                delegatedAccesses && delegatedAccesses.map((item, index) =>
                                    <div className="col-12 col-sm-6 col-md-4" key={index}>
                                        <Link className="text-decoration-none" to={`/delegates/${item.userId}`}>
                                            <DelegatedAccessItem data={item} />
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}