const moment = require('moment-timezone');

function getRelativeTime (date) {
    const timezone = moment.tz.guess();
    const offsetTime = moment().tz(timezone).utcOffset();
    const d = moment(date);
    const cd = d.add(offsetTime, 'miniutes');
    const t = moment();
    if (t.diff(cd, 'days') > 30) {
        cd.format('MMM Do YY');
    } else {
        return cd.startOf('minute').fromNow();
    }        
}

function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

function isJsonString(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }        
}

module.exports = {
    getRelativeTime,
    isValidEmail,
    isJsonString,
}