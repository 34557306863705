import React, { useState } from "react";
import { Box, MenuItem, Popover, Skeleton, Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import config from "../../../../config/config";

export default function StoryDetailSetting(props) {
  const { handleDetail, handleSharing, handleGuide } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canBeOpen = Boolean(anchorEl);
  const id = canBeOpen ? "setting-anchor" : undefined;

  return (
    <Box>
      <SettingsIcon
        sx={{
          cursor: "pointer",
          color: config.colors.greyTextColor,
          fontSize: 28,
        }}
        aria-describedby={id}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorEl}
        sx={{
          mt: 1,
          bgcolor: "transparent",
          "& .MuiPaper-root": {
            bgcolor: "transparent !important",
            boxShadow: "none",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            border: `3px solid ${config.colors.greyTextColor}`,
            borderRadius: "4px",
            bgcolor: config.colors.bgWhiteColor,
            position: "relative",
            mt: 1,
            "&:after": {
              bottom: "100%",
              left: "7%",
              border: "solid transparent",
              content: '" "',
              height: 0,
              width: 0,
              position: "absolute",
              pointerEvents: "none",
              borderBottomColor: config.colors.greyTextColor,
              borderWidth: "10px",
              marginLeft: "-10px",
            },
          }}
        >
          <MenuItem
            sx={{
              p: "8px 16px",
              borderBottom: `1px dotted ${config.colors.greyTextColor}`,
              color: config.colors.primaryTextColor,
            }}
            onClick={() => {
              handleClose();
              handleDetail();
            }}
          >
            Story Details
          </MenuItem>
          <MenuItem
            sx={{
              p: "8px 16px",
              borderBottom: `1px dotted ${config.colors.greyTextColor}`,
              color: config.colors.primaryTextColor,
            }}
            onClick={() => {
              handleClose();
              handleSharing();
            }}
          >
            Sharing Details
          </MenuItem>
          <MenuItem
            sx={{
              p: "8px 16px",
              color: config.colors.primaryTextColor,
            }}
            onClick={() => {
              handleClose();
              handleGuide();
            }}
          >
            Writing Guide
          </MenuItem>
        </Box>
      </Popover>
    </Box>
  );
}
