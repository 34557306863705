import React, { createContext, useContext, useState } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';

const UserContext = createContext();

const UserContextProvider = (props) => {

    const { axiosInstance } = useContext(AxiosContext);
    const [myFollowings, setMyFollowings] = useState([]);
    const [userFollowings, setUserFollowings] = useState([]);
    const [otherUser, setOtherUser] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [topAuthors, setTopAuthors] = useState([]);

    const getUser = (userId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.users + '/' + userId,
        }).then(response => {
            if (response.data.status) {
                console.log('== user/userId >>>>', response.data);
                setOtherUser(response.data.data);
                setUserDetail(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getMyProfile = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.users + '/my',
        }).then(response => {
            if (response.data.status) {
                console.log('-=-=-=- profile/userId >>>>', response.data);
                setUserDetail(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getMyFollowings = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.users + '/follow/my',
        }).then(response => {
            if (response.data.status) {
                setMyFollowings(response.data.data.followers);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getUserFollowings = (userId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.users + '/follow/' + userId,
        }).then(response => {
            if (response.data.status) {
                setUserFollowings(response.data.data.followers);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const follow = (userId, data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.users + '/follow/' + userId,
            data: data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const updateUser = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.users + '/' + data.id,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getTopAuthors = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.users + '/topAuthors',
        }).then(response => {
            if (response.data.status) {
                setTopAuthors(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const changeUsername = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.users + '/username/' + data.userId,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const changePassword = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.users + '/password/' + data.userId,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const changeEmail = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.users + '/email/' + data.userId,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const providerValue = {
        getUser,
        getUserFollowings,
        follow,
        getMyFollowings,
        myFollowings,
        otherUser,
        userFollowings,
        setUserFollowings,
        updateUser,
        getMyProfile,
        userDetail,
        getTopAuthors,
        topAuthors,
        changeUsername,
        changePassword,
        changeEmail
    }

    return (
        <UserContext.Provider value={providerValue}>
            {props.children}
        </UserContext.Provider>
    );

}

export {
    UserContext, UserContextProvider
}