import React from "react";
import { Box, Grid, Skeleton, Stack } from "@mui/material";

export default function StoryListItemSkeleton() {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={3}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Grid>
        <Grid item xs={12} md={7} lg={9}>
          <Stack direction={"column"} spacing={1}>
            <Skeleton variant="rounded" width={300} height={25} />
            <Skeleton variant="rounded" width={400} height={35} />
            <Skeleton variant="rounded" width={"100%"} height={120} />
            <Stack direction={"row"} spacing={2}>
              {[...Array(5)].map((tag, index) => (
                <Skeleton
                  variant="rounded"
                  width={40}
                  height={25}
                  key={index}
                  tag={tag}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
