import React, { createContext, useContext, useState } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';

const DelegateContext = createContext();

const DelegateContextProvider = (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const [delegatedAccesses, setDelegatedAccesses] = useState([]);
    const [delegatorInfo, setDelegatorInfo] = useState({});
    const [delegateStories, setDelegateStories] = useState([]);

    const sendInviteToCoWriters = (data) => {
        return axiosInstance({
            method: 'POST',
            url: `${apis.delegate}/invite`,
            data
        }).then(response => {
            console.log(response.data);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const checkInviteToken = (inviteToken) => {
        return axiosInstance({
            method: 'POST',
            url: `${apis.delegate}/invite/check/${inviteToken}`,
        }).then(response => {
            console.log(response.data);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getDelegatedAccesses = () => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.delegate}/getDelegatedAccesses`,
        }).then(response => {
            if(response.data.status) {
                setDelegatedAccesses(response.data.data);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getDelegateStoriesByUserId = (userId) => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.delegate}/getDelegateStoriesByUserId/${userId}`
        }).then(response => {
            if(response.data.status) {
                if(response.data.data.user) {
                    setDelegatorInfo(response.data.data.user);
                    setDelegateStories(response.data.data.user.stories);
                }
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const providerValue = {
        sendInviteToCoWriters,
        checkInviteToken,
        delegatedAccesses,
        getDelegatedAccesses,
        delegatorInfo,
        delegateStories,
        getDelegateStoriesByUserId
    }

    return (
        <DelegateContext.Provider value={providerValue}>
            {props.children}
        </DelegateContext.Provider>
    );

}

export {
    DelegateContext, DelegateContextProvider
}