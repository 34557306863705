import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { AdminLayout } from "../../layouts";
import { AdminContext } from "../../../context/admin_context";
import { PrimaryButton, SendEmailConfirmModal } from "../../components";
import { toast } from "react-toastify";

export default function EmailTemplateList() {
  const { adminSendGridEmails, sendSendGridEmail } = useContext(AdminContext);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    setLoading(true);
    const result = await adminSendGridEmails();
    setItems(result?.data?.items ?? []);
    setLoading(false);
  };

  const sendEmail = async () => {        
    const data = {
        templateId: selectedItem?.id,
    };
    setLoading(true);
    const result = await sendSendGridEmail(data);
    toast.success("Successfully Sent!");
    setLoading(false);
  };

  const renderTemplate = (item) => {
    let template = null;
    item.versions?.map((version) => {
      if (version?.active == 1) {
        template = {
          ...version,
          name: item.name,
          generation: item.generation,
          id: item.id,
        };
      }
    });

    return (
      <Stack
        direction={{
          md: "row",
          sm: "column",
        }}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
            borderRadius: 4,
            border: `1px solid grey`,
            p: 2,
        }}
      >
        <Box>
          <Typography
            sx={{
                fontSize: 20,
                fontWeight: 600,
            }}
          >{template.name}</Typography>
          <Typography>{template.subject}</Typography>
        </Box>

        <Box
          sx={{
            maxWidth: {
              md: "240px",
              sm: "100%",
            }
          }}
        >
          <img src={template.thumbnail_url} alt="" style={{
              width: "100%",
          }}/>
        </Box>
        <PrimaryButton
            label={"Send"}
            handleClick={() => {
                setSelectedItem(template);
                setOpenModal(true);
            }}
            disabled={loading}
        />
      </Stack>
    );
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Grid container spacing={2}>
          {items?.map((item, index) => {
            return (
              <Grid key={index} item xs={12} sm={6}>
                {renderTemplate(item)}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <SendEmailConfirmModal
        isOpenModal={openModal}
        closeModal={() => {
            setOpenModal(false);
        }}
        confirm={() => {
            setOpenModal(false);
            sendEmail();
        }}
      />
    </AdminLayout>
  );
}
