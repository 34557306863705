import React, { createContext, useState } from 'react';

const GuestContext = createContext();

const GuestContextProvider = (props) => {

    const [isScrolling, setIsScrolling] = useState(false);

    const setTempStory = (data) => {
        localStorage.setItem('tempStory', JSON.stringify(data));
    }
    
    const getTempStory = () => {
        const saved = localStorage.getItem('tempStory');
        return saved !==null ? JSON.parse(saved) : null;
    }

    const removeTempStory = () => {
        localStorage.removeItem('tempStory');
    }

    const setTempStoryPart = (data) => {
        localStorage.setItem('tempStoryPart', JSON.stringify(data));
    }
    
    const getTempStoryPart = () => {
        const saved = localStorage.getItem('tempStoryPart');
        return saved !==null ? JSON.parse(saved) : null;
    }

    const removeTempStoryPart = () => {
        localStorage.removeItem('tempStoryPart');
    }

    const setPrevRoute = (data) => {
        localStorage.setItem('PrevRoute', JSON.stringify(data));
    }
    
    const getPrevRoute = () => {
        const saved = localStorage.getItem('PrevRoute');
        return saved !==null ? JSON.parse(saved) : null;
    }

    const removePrevRoute = () => {
        localStorage.removeItem('PrevRoute');
    }

    const setTempUserData = (data) => {
        localStorage.setItem('tempUser', JSON.stringify(data));
    }

    const getTempUserData = () => {
        const saved = localStorage.getItem('tempUser');
        return saved !==null ? JSON.parse(saved) : null;
    }

    const removeTempUserData = () => {
        localStorage.removeItem('tempUser');
    }

    const providerValue = {
        getTempStory,
        setTempStory,
        removeTempStory,
        getTempStoryPart,
        setTempStoryPart,
        removeTempStoryPart,
        setPrevRoute,
        getPrevRoute,
        removePrevRoute,
        setTempUserData,
        getTempUserData,
        removeTempUserData,
        setIsScrolling,
        isScrolling,
    }

    return (
        <GuestContext.Provider value={providerValue}>
            {props.children}
        </GuestContext.Provider>
    );
}

export {
    GuestContext, GuestContextProvider
}
