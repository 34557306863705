import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../../styles/write.scss';

import { MainHeader, Footer } from '../../components';
import Config from '../../../config/config';
import { AxiosContext, ServiceContext, StoryContext, CategoryContext, AuthContext, GuestContext } from '../../../context';
import { toast } from 'react-toastify';

export default function StoryPage() {

  const navigate = useNavigate();
  const { storyId, storyNoteId } = useParams();
  const { setLoading } = useContext(AxiosContext);
  const { getStoryNoteById, updateStoryNote } = useContext(StoryContext);
  const { token, user } = useContext(AuthContext);
  const { getTempStory, setTempStory, removeTempStory, getPrevRoute } = useContext(GuestContext);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getStoryNote(storyNoteId);
  }, [token, storyNoteId]);

  const getStoryNote = (storyNoteId) => {
    getStoryNoteById(storyNoteId).then(res => {
      console.log('==??', res);
      if(res.status) {
        setFormData(res.data);
      }
    });
  }

  const handleSubmit = () => {
    const fd = formData;
    delete fd.user;
    delete fd.story;
    console.log('fd', fd);
    updateStoryNote(fd).then(res => {
      if(res.status) {
        toast('Saved successfully.');
      }
    });
  }

  const handleBack = () => {
    let prevRoute = getPrevRoute();
    navigate(prevRoute ? prevRoute.pathname : '/library?tab=saved');
  }

  return (
    <div className="page write">
      <div className="write-header">
        <MainHeader />
        <div className={`write-navbar`}>
          <div className="container">
            <div className="sub-container">
              <div className="navbar-wrapper">
                <div className="author-profile">
                  <div className="story-info ps-0">
                    <div className="story-title">{formData?.story.title}</div>
                    <div className="story-mode">{formData?.story ? (formData?.story.status.charAt(0).toUpperCase() + formData?.story.status.slice(1).toLowerCase()) : ''}</div>
                    <div className="button-group d-flex d-sm-none">
                      <button className="btn btn-secondary btn-cancel"
                        onClick={() => handleBack()}
                      >Cancel</button>
                      <button className="btn btn-primary btn-skip"
                        onClick={() => handleSubmit()}
                      >Save</button>
                    </div>
                  </div>
                </div>
                <div className="button-group ms-auto d-none d-sm-flex">
                  <button className="btn btn-secondary btn-cancel"
                    onClick={() => handleBack()}
                  >Back</button>
                  <button className="btn btn-primary btn-skip"
                    onClick={() => handleSubmit()}
                  >Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="story-form story-note">
        <div className="container">
          <div className="sub-container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <h1 className="headline mb-5">Story Notes</h1>
                <h2 className="subline mb-4">The Protagonist</h2>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>Your Protagonist's name</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <input type="text" className="form-control" placeholder="Enter Name" 
                      value={formData?.protagonistName || ''} 
                      onChange={(e) => setFormData({...formData, protagonistName: e.target.value})}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>Other names for the Protagonist</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <textarea className="form-control" rows={2} maxLength={50} 
                      value={formData?.protagonistOtherNames || ''} 
                      onChange={(e) => setFormData({...formData, protagonistOtherNames: e.target.value})}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>Protagonist's pronoun</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <select className="form-control"
                      name="protagonist_pronoun" 
                      value={formData?.protagonistPronoun || ''}
                      onChange={(e) => setFormData({...formData, protagonistPronoun: e.target.value})}
                    >
                      <option value="">Select a Category</option>
                      <option value="He">He</option>
                      <option value="She">She</option>
                      <option value="They">They</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>Protagonist's attributes</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <input type="text" className="form-control" 
                      value={formData?.protagonistAttributes || ''}
                      onChange={(e) => setFormData({...formData, protagonistAttributes: e.target.value})}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>The Protagonist tends to be:</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Extroverted</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsExtrovertedOrIntroverted || 0}
                      onChange={(e) => setFormData({...formData, tendsExtrovertedOrIntroverted: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Introverted</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Unlikable</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsUnlikableOrLikable || 0}
                      onChange={(e) => setFormData({...formData, tendsUnlikableOrLikable: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Likable</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Focused on Patterns in the Past</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsFocusOnPastOrFuture || 0}
                      onChange={(e) => setFormData({...formData, tendsFocusOnPastOrFuture: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Focused on Future Possibilities</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Uses Mind & Logic</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsUseMindOrHeart || 0}
                      onChange={(e) => setFormData({...formData, tendsUseMindOrHeart: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Uses Heart & Emotion</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Organized & Decisive Decision Making</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsOrganizedOrImprovised || 0}
                      onChange={(e) => setFormData({...formData, tendsOrganizedOrImprovised: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Improvised & Open Decision Making</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Light & Laid Back Attitude</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.tendsLightOrComplex || 0}
                      onChange={(e) => setFormData({...formData, tendsLightOrComplex: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Complex & Serious Attitude</label>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>How will the Protagonist's personality change by the end of the story?</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <select className="form-control"
                      value={formData?.protagonistPersonalityChangeByEnd || ''}
                      onChange={(e) => setFormData({...formData, protagonistPersonalityChangeByEnd: e.target.value})}
                    >
                      <option value="">Select</option>
                      <option value="Negative Personality Change">Negative Personality Change</option>
                      <option value="No/Minimal Personality Change">No/Minimal Personality Change</option>
                      <option value="Positive Personality Change">Positive Personality Change</option>
                    </select>
                  </div>
                </div>
                <hr />
                <h2 className="subline my-4">The Story Goal</h2>
                <div className="row mb-4">
                  <div className="col-12">
                    <label>What is your Protagonist's ultimate goal in this story?</label>
                  </div>
                  <div className="col-12">
                    <textarea className="form-textarea" rows={3} maxLength={75}
                      value={formData?.protagonistUltimateGoal || ''}
                      onChange={(e) => setFormData({...formData, protagonistUltimateGoal: e.target.value})}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="mb-3">The goal is primarily:</label>
                  </div>
                  <div className="col-12">
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="primaryGoal" 
                        value="Physical" 
                        checked={formData?.primaryGoal === 'Physical'}
                        onChange={(e) => setFormData({...formData, primaryGoal: e.target.value})}
                      />Physical
                    </label>
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="primaryGoal" 
                        value="Emotional" 
                        checked={formData?.primaryGoal === 'Emotional'}
                        onChange={(e) => setFormData({...formData, primaryGoal: e.target.value})}
                      />Emotional
                    </label>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-sm-6">
                    <label>The goal type is to:</label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <select className="form-control"
                      value={formData?.goalType || ''} 
                      onChange={(e) => setFormData({...formData, goalType: e.target.value})}
                    >
                      <option value="">Select</option>
                      {
                        formData?.primaryGoal === 'Physical' && 
                        <>
                          <option value="Acquire">Acquire</option>
                          <option value="Explore">Explore</option>
                          <option value="Escape">Escape</option>
                          <option value="Find">Find</option>
                          <option value="Protect">Protect</option>
                          <option value="Survive">Survive</option>
                        </>
                      }
                      {
                        formData?.primaryGoal === 'Emotional' && 
                        <>
                          <option value="Accept">Accept</option>
                          <option value="Appreciate">Appreciate</option>
                          <option value="Forget">Forget</option>
                          <option value="Learn">Learn</option>
                          <option value="Love">Love</option>
                          <option value="Self Actualize">Self Actualize</option>
                        </>
                      }
                    </select>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <label>To achieve the story goal the Protagonist has to...</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Change themselves</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToChangeThemselvesOrSurroundings || 0} 
                      onChange={(e) => setFormData({...formData, hasToChangeThemselvesOrSurroundings: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Change their surroundings</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Explore their emotions</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToExploreEmotionOrWorld || 0} 
                      onChange={(e) => setFormData({...formData, hasToExploreEmotionOrWorld: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Explore their world</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Navigates a familiar world</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToFamiliarOrUnfamiliarWorld || 0} 
                      onChange={(e) => setFormData({...formData, hasToFamiliarOrUnfamiliarWorld: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Encounters an unfamiliar world</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Ignore the Past</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToIgnoreOrRecallPast || 0} 
                      onChange={(e) => setFormData({...formData, hasToIgnoreOrRecallPast: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Recall the past</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Reappreciate the current meaning</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToCurrentOrNewMeaning || 0} 
                      onChange={(e) => setFormData({...formData, hasToCurrentOrNewMeaning: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Find a new meaning</label>
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label className="d-flex justify-content-end text-end">Take something away from the antagonist</label>
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <input type="range" className="w-100" min={-5} max={5} step={1} 
                      value={formData?.hasToTakeOrKeepFromAntagonist || 0} 
                      onChange={(e) => setFormData({...formData, hasToTakeOrKeepFromAntagonist: e.target.value})}
                    />
                  </div>
                  <div className="col-3">
                    <label>Keep something away from the antagonist</label>
                  </div>
                </div>
                <hr />
                <h2 className="subline my-4">The Story Outcome</h2>
                <div className="row mb-4">
                  <div className="col-12">
                    <label>What happens at the end of the story?</label>
                  </div>
                  <div className="col-12">
                    <textarea className="form-textarea" rows={3} maxLength={150}
                      value={formData?.happensAtEnd || ''} 
                      onChange={(e) => setFormData({...formData, happensAtEnd: e.target.value})} 
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="mb-3">Will the Protagonist succeed in reaching the goal at the end of this story?</label>
                  </div>
                  <div className="col-12">
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="protagonistSucceedAtEnd"
                        value="Yes" 
                        checked={formData?.protagonistSucceedAtEnd === 'Yes'} 
                        onChange={(e) => setFormData({...formData, protagonistSucceedAtEnd: e.target.value})}
                      />Yes
                    </label>
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="protagonistSucceedAtEnd"
                        value="No" 
                        checked={formData?.protagonistSucceedAtEnd === 'No'} 
                        onChange={(e) => setFormData({...formData, protagonistSucceedAtEnd: e.target.value})}
                      />No
                    </label>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="mb-3">How will the Protagonist feel about the outcome from achieving the goal?</label>
                  </div>
                  <div className="col-12">
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="protagonistOutcomeFeeling"
                        value="Good" 
                        checked={formData?.protagonistOutcomeFeeling === 'Good'} 
                        onChange={(e) => setFormData({...formData, protagonistOutcomeFeeling: e.target.value})}
                      />Good
                    </label>
                    <label className="d-flex align-items-center me-3 mb-3">
                      <input type="radio" className="me-2" 
                        name="protagonistOutcomeFeeling"
                        value="Bad" 
                        checked={formData?.protagonistOutcomeFeeling === 'Bad'} 
                        onChange={(e) => setFormData({...formData, protagonistOutcomeFeeling: e.target.value})}
                      />Bad
                    </label>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-12">
                    <button className="btn btn-primary btn-done"
                      onClick={() => handleSubmit()}
                    >Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* Modals */}
      {/* Import A Story Modal */}
     
    </div>
  );
}