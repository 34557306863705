import React from "react";
import { Button } from '@mui/material';

export default function DefaultButton(props) {

    const {
        handleClick,
        label,
        width = 100,
        height = 50,
        startIcon,
        sx
    } = props;

    return <Button
    onClick={handleClick}
    variant="contained"
    sx={{
        textTransform: "none",
        bgcolor: "#fff",
        fontFamily: "Libre Franklin",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#141a46",
        border: "2px solid #d9d9d9",
        borderRadius: "8px",
        "&:hover": {
            bgcolor: "#141a46",
            color: "#fff",
            border: "none",
            boxShadow: "none",
        },
        "&:disabled": {
            bgcolor: "#fff",
            opacity: 0.5,
            boxShadow: "none",
        },
        height: height,
        width: width,
        boxShadow: "none",
        ...sx,
    }}
    startIcon={startIcon ? startIcon : null}
    >
        {label}
    </Button>;
}