import defaultAvatar from '../../../assets/images/avatar.svg';
import moment from 'moment';

export default function DelegatedAccessItem({data}) {
    return (
        <div className="user-list-item card p-3 shadow-sm">
            <div className="d-flex flex-row">
                <div style={{width: '90px', height: '90px'}}>
                    <div className="user-photo-wrapper">
                        <img className="w-100 h-100 object-fit-cover" src={data?.user.imageUrl ? data?.user.imageUrl : defaultAvatar} alt="" />
                    </div>
                </div>
                <div className="user-info ps-3">
                    <div className="user-name text-decoration-none mb-1">{data?.user.fullName ? data?.user.fullName : data?.user.username}</div>
                    <div className="user-followers">{data?.user.followerUsers.length} Followers • {data?.user.followingUsers.length} Following</div>
                    <div className="invited-at text-dark">Invited on {data?.createdAt ? moment(data?.createdAt).format("MMM DD, YYYY") : ''}.</div>
                </div>
            </div>
        </div>
    )
}