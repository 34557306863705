import React from "react";
import { Button } from '@mui/material';

export default function PrimaryButton(props) {

    const {
        handleClick,
        label,
        width = 100,
        height = 50,
        startIcon,
        sx,
        disabled=false
    } = props;

    return <Button
    onClick={handleClick}
    variant="contained"
    sx={{
        textTransform: "none",
        bgcolor: "#DB805E",
        fontFamily: "Libre Franklin",
        fontWeight: "600",
        fontSize: {
            sm: "20px",
            xs: "16px"
        },
        lineHeight: "30px",
        color: "white",
        borderColor: "#DB805E",
        borderRadius: "8px",
        "&:hover": {
            bgcolor: "#A15C42",
            borderColor: "#A15C42",
            boxShadow: "none",
        },
        "&:disabled": {
            bgcolor: "#DB805E",
            borderColor: "#DB805E",
            opacity: 0.5,
            boxShadow: "none",
        },
        height: height,
        width: width,
        boxShadow: "none",
        ...sx,
    }}
    startIcon={startIcon ? startIcon : null}
    disabled={disabled}
    >
        {label}
    </Button>;
}