import React from 'react'
import { useNavigate, useParams } from "react-router-dom"

export default function StoryTag({tag}) {
    const navigate = useNavigate();
    const { tagsSlug } = useParams();

    const handleClickTag = (_tag) => {
        const tagsArr = tagsSlug ? tagsSlug?.split(',') : [];
        if(tagsArr?.includes(_tag.trim())) {
            tagsArr.splice(tagsArr.indexOf(_tag.trim()), 1);
        } else {
            tagsArr.push(_tag.trim());
        }
        const newPath = `/stories/tags/${encodeURIComponent(tagsArr.toString())}`;
        navigate(newPath);
    }

    return (
        <span className={`story-tag ${tagsSlug?.split(',').includes(tag.trim()) ? 'active' : ''}`} 
            onClick={() => handleClickTag(tag)}
        >{tag}</span>
    )
}