import React, { useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Footer, MainHeader, StoryTag, StoryListItem } from '../../components';
import { SearchContext, AxiosContext } from '../../../context';

export default function TagsStoriesPage() {

    const { tagsSlug } = useParams();
    const {
        searchStories,
        filterStories,
      } = useContext(SearchContext);
      const { setLoading } = useContext(AxiosContext);

    useEffect(() => {
        setLoading(true);
        searchStories('', {tags: tagsSlug, status: "PUBLISH"}).then((res) => {
            console.log('>>res>>', res.data)
            setLoading(false);
        });
    }, [tagsSlug]);
  
    return (
        <div className="page stories bg-white">
            <MainHeader />
            <section className="search-params">
              <div className="container position-relative">
                  <div className="banner-wrapper sub-container">
                      <h1 className="headline position-relative zindex-100">
                        {
                            tagsSlug && tagsSlug.split(',').map((tag, index) =>
                                <StoryTag tag={tag} key={index} />
                            )
                        }
                      </h1>
                      <p className="subline position-relative zindex-100">{filterStories.length} {filterStories.length > 1 ? 'results' : 'result'}</p>
                  </div>
              </div>
            </section>
            <section className="section-stories">
                <div className="container">
                    <div className="story-list sub-container">
                        {
                            filterStories && filterStories.map((item, index) =>
                                <StoryListItem item={item} key={index} />
                            )
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}