import React, { useState } from "react";
import { Pagination, Stack, Typography } from "@mui/material";

export default function TablePagination(props) {
  const {
    totalPage,
    totalCount,
    page = 1,
    onChangePage,
    itemName = "Items",
  } = props;
  const [currentPage, setCurrentPage] = useState(page);
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={1}
    >
      <Typography
        variant="body2"
      >
        Page {page} of {totalPage} | {totalCount} {itemName}
      </Typography>
      <Pagination
        count={totalPage}
        shape="rounded"
        size=""
        showFirstButton
        showLastButton
        // boundaryCount={2}
        page={currentPage}
        onChange={(e, v) => {
          setCurrentPage(v);
          onChangePage(v);
        }}
      />
    </Stack>
  );
}
