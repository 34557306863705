import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const AuthPromptModal = ({ showModal, setShowModal, redirect }) => {
    const navigate = useNavigate();

    const handleExit = () => {
        setShowModal(false);
        // navigate(-1);
    }

    const handleSignUp = () => {
        setShowModal(false);
        navigate(`/auth/register${redirect ? '?redirect=' + redirect : ''}`);
    }

    const handleLogin = () => {
        setShowModal(false);
        navigate(`/auth/login${redirect ? '?redirect=' + redirect : ''}`);
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop="static"
            keyboard={false}
            className="login-prompt-modal"
        >
            <Modal.Header className="border-0">
            </Modal.Header>
            <Modal.Body>
                <p className="subline text-center fw-bold">
                    Please Login or Sign Up to continue...
                </p>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="primary" 
                    className="btn-login mx-auto"
                    onClick={() => handleLogin()}
                >
                    Login
                </Button>
                <Button variant="primary" 
                    className="btn-signup mx-auto"
                    onClick={() => handleSignUp()}
                >
                    Sign Up
                </Button>
                <Button variant="primary" 
                    className="btn-exit mx-auto"
                    onClick={() => handleExit()}
                >
                    Exit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AuthPromptModal;