/* eslint-disable import/no-anonymous-default-export */
export default {
    defaultPath: '/',
    basename: '', // only at build time to set, like /datta-able
    WPM: 200, // words per minute
    WPS: 200 / 60, // words per second
    defaultStoryDetails: {
        title: 'Untitled Story',
        description: '',
        categoryId: 1,
        type: '',
        tags: '',
        language: '',
        copyright: '',
        rating: false,
        logo: null
    },
    defaultWriteInfo: {
        title: "",
        prompt: "",
        description: "",
        coverVideo: "",
        isVideo: false,
        coverImage: "",
        isImage: false
    },
    communities: ["Community One", "Community Two", "Community Three"],
    communitySlugs: ["community-one", "community-two", "community-three"],
    languages: ["English", "Spanish", "French", "German", "Chinese", "Japanese"],
    targetAudiences: ["45-55", "56-60"],
    copyrights: ["All Rights Reserved", "Public Domain", "Creative Commons (CC) Attribution", "(CC) Attrib. NonCommercial", "(CC) Attrib. NonComm. NoDerivs", "(CC) Attrib. NonComm. ShareAlike", "(CC) Attribution-ShareAlike", "(CC) Attribution-NoDerivs"],
    auth: {
        default: {
            password: "storymoir",
        },
        provider: {
            email: 'EMAIL',
            google: 'GOOGLE',
            facebook: 'FACEBOOK'
        }
    },
    entityType: {
        image: 'IMAGE',
        video: 'VIDEO',
        audio: 'AUDIO',
        emoji: 'EMOJI'
    },
    uploadMaxSize: {
        video: 10000000, // bytes
        image: 5000000, // bytes
        audio: 8000000, // bytes
    },
    social: {
        google: { // victman1121@gmail.com
            // clientId: "198965168935-mjvakrnag9nlsr79i0gf55hhaeqbihc7.apps.googleusercontent.com",
            // clientSecret: "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"
            clientId: "626107034444-epaj8tglsjq7edearskkttgse9f52h4b.apps.googleusercontent.com",
            clientSecret: "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"
            // clientId: "968989060545-ud3ov1io1bptmgj0jcudl120pukdg71c.apps.googleusercontent.com",
            // clientSecret: "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"
        },
        facebook: {
            // appId: "593243659361087",
            // secret: "066de494eab7b54ad31ad9e7a0b43e90",
            appId: "1210787882933935",
            secret: "f94a5cb7831707b7e34c19ce2fa6608b"
        }
    },
    googleDrive: {
        clientId: "198965168935-mjvakrnag9nlsr79i0gf55hhaeqbihc7.apps.googleusercontent.com",
        clientSecret: "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",
        apiKey: "AIzaSyAPOFXuCCjhy9jZENVzmzT527c9wjlqmII" //AIzaSyCinydNrK-CMcOmN6K9ohk9YAsbvAlp-yw
        // apiKey: "AIzaSyCinydNrK-CMcOmN6K9ohk9YAsbvAlp-yw"
    },
    dropbox: {
        appKey: "8gfm65kag7kqx7t"
    },
    query: {
        limit: 10,
    },
    colors: {
        defaultColor: '#E1805E',
        bgWhiteColor: '#fff',
        primaryTextColor: '#141A46',
        greyTextColor: "#abacb8",
        primaryBgColor: "rgba(171, 172, 184, 0.15)",
        secondBgColor: "#e6e6e9",
        lightBlueColor: "#ABACB8",
    },
    customStyleMap: {
        FONT_SIZE_8: {
          fontSize: "8px",
        },
        FONT_SIZE_10: {
          fontSize: "10px",
        },
        FONT_SIZE_12: {
          fontSize: "12px",
        },
        FONT_SIZE_14: {
          fontSize: "14px",
        },
        FONT_SIZE_16: {
          fontSize: "16px",
        },
        FONT_SIZE_18: {
          fontSize: "18px",
        },
        FONT_SIZE_20: {
          fontSize: "20px",
        },
        FONT_SIZE_22: {
          fontSize: "22px",
        },
        FONT_SIZE_24: {
          fontSize: "24px",
        },
        FONT_SIZE_26: {
          fontSize: "26px",
        },
        FONT_SIZE_28: {
          fontSize: "28px",
        },
        FONT_SIZE_30: {
          fontSize: "30px",
        },
        FONT_SIZE_32: {
          fontSize: "32px",
        },
        FONT_SIZE_34: {
          fontSize: "34px",
        },
        FONT_SIZE_36: {
          fontSize: "36px",
        },
        FONT_SIZE_38: {
          fontSize: "38px",
        },
        FONT_SIZE_40: {
          fontSize: "40px",
        },
        FONT_SIZE_42: {
          fontSize: "42px",
        },
        FONT_SIZE_44: {
          fontSize: "44px",
        },
        FONT_SIZE_46: {
          fontSize: "46px",
        },
        FONT_SIZE_48: {
          fontSize: "48px",
        },
      },
      FONT_SIZE_LIST: [
        "FONT_SIZE_8",
        "FONT_SIZE_10",
        "FONT_SIZE_12",
        "FONT_SIZE_14",
        "FONT_SIZE_16",
        "FONT_SIZE_18",
        "FONT_SIZE_20",
        "FONT_SIZE_22",
        "FONT_SIZE_24",
        "FONT_SIZE_26",
        "FONT_SIZE_28",
        "FONT_SIZE_30",
        "FONT_SIZE_32",
        "FONT_SIZE_34",
        "FONT_SIZE_36",
        "FONT_SIZE_38",
        "FONT_SIZE_40",
        "FONT_SIZE_42",
        "FONT_SIZE_44",
        "FONT_SIZE_46",
        "FONT_SIZE_48",
      ],
};