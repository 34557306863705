import React, { createContext, useContext, useEffect, useState } from 'react';
import { AxiosContext, AuthContext } from '../context';
import apis from '../config/apis';

const CategoryContext = createContext();

const CategoryContextProvider = (props) => {

    const { axiosInstance } = useContext(AxiosContext);
    const { token } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.categories,
        }).then(response => {
            if (response.data.status) {
                setCategories(response.data.data);
            }
            return response.data.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const providerValue = {
        getCategories,
        categories,
    }

    return (
        <CategoryContext.Provider value={providerValue}>
            {props.children}
        </CategoryContext.Provider>
    );

}

export {
    CategoryContext, CategoryContextProvider
}