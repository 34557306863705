import React, { useEffect, useContext } from "react";
import { Footer, MainHeader, UserListItem } from '../../components';
import { UserContext, AxiosContext } from '../../../context';

export default function AuthorsPage() {
    const {
        getTopAuthors,
        topAuthors,
    } = useContext(UserContext);
    const { setLoading } = useContext(AxiosContext);

    useEffect(() => {
        getAuthors();
    }, []);

    const getAuthors = () => {
        setLoading(true);
        getTopAuthors().then((res) => {
            console.log('>>res>>', res.data)
            setLoading(false);
        });
    }

    return (
        <div className="page stories bg-white">
            <MainHeader />
            <section className="section-header">
                <div className="container">
                    <div className="sub-container">
                        <div className="row m-0 w-100">
                            <div className="col-12 col-sm-6 px-0 d-flex align-items-center">
                                <h1 className="headline m-0">Top Authors</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-stories">
                <div className="container">
                    <div className="sub-container">
                        <hr className="divider" />
                        <div className="user-list">
                        {
                            topAuthors && topAuthors.map((item, index) =>
                                <UserListItem key={index} item={item} />
                            )
                        }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}