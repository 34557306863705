import {  useNavigate, useParams } from "react-router-dom";
import { Footer, MainHeader, StoryListItem, UserListItem } from '../components';
import '../styles/search.scss';
// import data from './stories/dummy_data';
import { useEffect, useState, useContext } from "react";
import { SearchContext, AxiosContext } from '../../context';
// import imgAuthor from '../../assets/images/avatar.svg';

export default function SearchPage() {
    const navigate = useNavigate();
    const { queryText } = useParams();
    const [filterParams, setFilterParams] = useState({
        storyLength: [],
        storyType: [],
        status: 'PUBLISH'
    });
    const {
        // searchText,
        searchStories,
        searchPeople,
        // setFilterStories,
        // setFilterUsers,
        filterStories,
        filterUsers,
        // filterStoriesPagination,
        // filterUsersPagination,
    } = useContext(SearchContext);
    const { setLoading } = useContext(AxiosContext);
    const [activeTab, setActiveTab] = useState('authors'); // authors, topics
    const [searchKeyward, setSearchKeyward] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(!queryText) {
            return;
        }
        if(activeTab === 'authors') {
            authorSearch();
        } else {
            storySearch();
        }
        setSearchKeyward(queryText);
    }, [queryText, filterParams]);

    const storySearch = () => {
        setLoading(true);
        searchStories(queryText, filterParams).then((res) => {
            console.log('>>res>>', res.data)
            setLoading(false);
        });
    }

    const authorSearch = () => {
        setLoading(true);
        searchPeople(queryText, filterParams).then((res) => {
            console.log('>>res>>', res.data)
            setLoading(false);
        });
    }

    const handelClickTab = (e) => {
        setActiveTab(e);
        if(e === 'authors') {
            authorSearch();
        } else {
            storySearch();
        }
    }

    const handleSearchKeyUp = (e) => {
        if(e.keyCode === 13) {
            navigate(`/search/${searchKeyward}`);
        }
    }

    return (
        <div className="page stories bg-white">
            <MainHeader />
            <section className="section-header">
                <div className="container">
                    <div className="sub-container">
                        <div className="row m-0 w-100">
                            <div className="col-12 col-sm-6 px-0 d-flex align-items-center">
                                <h1 className="headline m-0">Browse</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-stories">
                <div className="container">
                    <div className="sub-container">
                        <ul className="story-tabs mb-0 nav nav-tabs border-0" role="tablist">
                            <li className="nav-item">
                                <button type="button" className={`nav-link ${activeTab === 'authors' ? 'active' : ''}`}
                                    onClick={() => handelClickTab('authors')}
                                >Authors</button>
                            </li>
                            <li className="nav-item">
                                <button type="button" className={`nav-link ${activeTab === 'topics' ? 'active' : ''}`}
                                    onClick={() => handelClickTab('topics')}
                                >Topics</button>
                            </li>
                        </ul>
                        <div className="search-form">
                            <span className="search-icon"></span>
                            <input type="text" className="search-input" placeholder="Enter author’s name, story title, or story tags here" 
                                value={searchKeyward}
                                onChange={(e) => setSearchKeyward(e.target.value)}
                                onKeyUp={(e) => handleSearchKeyUp(e)}
                            />
                        </div>
                        <div className="tab-content">
                            <div role="tabpanel" className={`fade tab-pane ${activeTab === 'authors' ? 'active show' : ''}`}>
                                <div className="user-list">
                                {
                                    filterUsers && filterUsers.map((item, index) =>
                                        <UserListItem key={index} item={item} />
                                    )
                                }
                                </div>
                            </div>
                            <div role="tabpanel" className={`fade tab-pane ${activeTab === 'topics' ? 'active show' : ''}`}>
                                <div className="story-list">
                                {
                                    filterStories && filterStories.map((item, index) =>
                                        <StoryListItem item={item} key={index} />
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}